export class Query {

    public id: string | null = null;
    public ids: string[] | null = null;
    public notIds: string[] | null = null;
    public publicAddress: string | null = null;
    public twitterId: string | null = null;

    public minCreatedAt: string|null = null
    public maxCreatedAt: string|null = null
    
    public offset:number | null = 0;
    public limit: number = 60;
    public sortBy: string | null = null

    public static fromJson(json?:{[key:string]:any}) {
        return new this(json)
    }

    constructor(options?: { [key: string]: any }) {
        if (options === undefined) {
            return;
        }
        if (options["id"] !== undefined) {
            this.id = options["id"];
        }
        if (options["ids"] !== undefined) {
            this.ids = options["ids"];
        }
        if (options["notIds"] !== undefined) {
            this.notIds = options["notIds"];
        }
        if (options["publicAddress"] !== undefined) {
            this.publicAddress = options["publicAddress"];
        }
        if (options["twitterId"] !== undefined) {
            this.twitterId = options["twitterId"];
        }
        if (options['minCreatedAt'] !== undefined) {
            this.minCreatedAt = options['minCreatedAt']
        }
        if (options['maxCreatedAt'] !== undefined) {
            this.maxCreatedAt = options['maxCreatedAt']
        }

        if (options["offset"] != undefined) {
            this.offset = options["offset"];
        }
        if (options["limit"] != undefined) {
            this.limit = options["limit"];
        }
        if (options["sort"] != undefined) {
            this.sortBy = options["sort"];
        }
    }

    public toJson(): {[key:string]:any} {
        return {
        id : this.id,
        ids : this.ids,
        notIds : this.notIds,
        publicAddress : this.publicAddress,
        twitterId: this.twitterId,
        minCreatedAt: this.minCreatedAt,
        maxCreatedAt: this.maxCreatedAt,
        offset : this.offset,
        limit :this.limit,
        sort : this.sortBy
}}
}
