import React, {Fragment,useEffect, useState} from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Link, useNavigate } from "react-router-dom";
import Box from '@mui/material/Box';
import styles from './Admins.module.css'
import { Button, Form, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import Sidebar from './Sidebar';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {UserController} from 'src/controllers/UserControllers';
import { useDispatch, useSelector } from "react-redux";
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { clearErrors } from 'src/redux/Actions/User';
import User from 'src/domain/modals/User';
import { UPDATE_USER_RESET } from 'src/redux/Constants/User';
import { toast } from 'react-toastify'
import Loader from 'src/components/Layout/Loader/Loader';

function Users() {
  const dispatch =  useDispatch();
  const Navigate = useNavigate();

  const {UserFindAll,UserUpdateRole,UserMe, UserFindByPublicAddress} = UserController()
  const {error:authUserError,loading:authUserLoading, user:authUser}  = useSelector((state) => state.user)
  const {error,loading, users}  = useSelector((state) => state.users)
  const {error: userDetailsError ,loading:userDetailsLoading, user}  = useSelector((state) => state.userDetails)
  
  const {
    loading: updateRoleLoading,
    error: updateRoleError,
    isUpdated,
  } = useSelector((state) => state.profile);
  const [editModalToggle, setEditModalToggle] = useState(false);
  const [publicAddress, setPublicAddress] = useState(null);
   const [role, setRole] = useState(null);

   const handleSubmit = async() =>{
      if(user){
        if(role ===null | role ===undefined){
          toast.warn("please input role")
           return;
        }
        const updateUser = new User(
          user.id,
          user.publicAddress,
          user.twitterId,
          user.nfts,
          user.language,
          role ,
          user.rememberMeToken,
          null,
          null,
          null
        )
        handleEditRoleModal();
        await UserUpdateRole(updateUser);
        
      }
      
    }

  const handleEditRoleModal = () => setEditModalToggle(!editModalToggle);
    const handleStatusSwitch = (event) => {
      setRole(event.target.checked)
    }
  useEffect(() => {
    UserFindAll()
    
  }, [isUpdated]);

  useEffect(() => {
    if(user &&   user.publicAddress !== publicAddress){
      if(publicAddress){
        UserFindByPublicAddress(publicAddress)
      }
      
    }
    if(!user){
      if(publicAddress){
        UserFindByPublicAddress(publicAddress)
      }
    }
    if(user){
      setRole(user.isAdmin==="true"?true :false)
    }
    if (error) {
      toast.error(error)
      dispatch(clearErrors());
    }
    if (authUserError) {
      toast.error(authUserError)
      dispatch(clearErrors());
    }
    if (userDetailsError) { 
      toast.error(userDetailsError)
      dispatch(clearErrors());
    }
    if (updateRoleError) {
      toast.error(updateRoleError);
      dispatch(clearErrors());
    }

    
    if (isUpdated) {
      if(authUser && user){
        if(authUser.id === user.id){
          UserMe()
        }
      }
      toast.success("User Updated Successfully");
      dispatch({ type: UPDATE_USER_RESET });
      
    }
  }, [dispatch, error,updateRoleError,authUserError, userDetailsError, user,isUpdated, publicAddress]);

    const columns = [ 
        { 
            field: "id", 
            headerName:"ID",
            headerClassName: 'super-app-theme--header',
            headerAlign: 'center',
            minWidth: 180, 
            flex: 0.8 
        },
        { 
            field: "publicAddress", 
            headerName:"Public Address",
            headerClassName: 'super-app-theme--header',
            headerAlign: 'center',
            minWidth: 180, 
            flex: 0.8 
        },
        {
            field: "twitterId",
            headerName: "twitterId",
            headerClassName: 'super-app-theme--header',
            headerAlign: 'center',
            minWidth: 150,
            flex: 0.6,
        },
        {
            field: "role",
            headerName: "Role",
            headerClassName: 'super-app-theme--header',
            type: "number",
            minWidth: 120,
            headerAlign: 'center',
            align: "center",
            flex: 0.2,
            cellClassName: (params) => {
                return params.row.role === "user"
                ? "redColor"
                : "greenColor";
            },
        },
        // { 
        //     field: "lastAccessedAt", 
        //     headerName:"Last LogedIn",
        //     headerClassName: 'super-app-theme--header',
        //     headerAlign: 'left',
        //     minWidth: 180, 
        //     flex: 0.6 
        // },
        { 
            field: "updatedAt", 
            headerName:"UpdatedAt",
            headerClassName: 'super-app-theme--header',
            headerAlign: 'center',
            minWidth: 180, 
            flex: 0.6 
        },
        {
          field: "actions",
          flex: 0.3,
          headerName: "Actions",
          headerClassName: 'super-app-theme--header',
          minWidth: 120,
          headerAlign: 'center',
          type: "number",
          align: "center",
          sortable: false,
          cellClassName: (params) => {
            return "redColors"
          },
        
          renderCell: (params) => {
            return (//to={`/admin/user/edit/${params.row.id}`}
                <Link className={styles.actionCell} onClick={()=>{
                    handleEditRoleModal();
                    setPublicAddress(params.row.publicAddress)
                    setRole(params.row.role==="admin"?true :false)}}> 
                  <EditIcon />
                </Link>
                
            );
          },
        },
    
]

const rows = [];

  users &&
    users.forEach((item) => {
      rows.push( 
        {
          id: item.id,
          publicAddress: item.publicAddress,
          twitterId: item.twitterId,
          role: item.isAdmin ==="true" ? "admin" :"user",
          //lastAccessedAt: item.lastAccessedAt,
          updatedAt:item.updatedAt
        });
    });


      return (
        <Fragment>
            <div className={styles.dashboard}>
            <Sidebar />
            <div className={styles.adminListContainer}>

              <h1 id={styles.adminListHeading}>USERS</h1>
              {
                loading ?
                 <Loader isLoading={loading ? true: false}/>
                :
                <Box 
                    sx={{
                        '& .super-app-theme--header': {
                            backgroundColor: "#1c1c1c20"
                        },
                        '& .redColors':{
                          color: "tomato"
                        },
                        '& .greenColor':{
                            color: "green"
                        },
                        '& .redColor':{
                            color: "tomato"
                        },
                        maxWidth: "95%",
                        alignSelf:"center",
                        boxShadow: "1px 1px 3px grey"
                    }}
                >
                  <DataGrid
                    autoHeight
                    rows={rows}
                    columns={columns}
                    pageSize={3}
                    disableSelectionOnClick
                    
                  />
                </Box>
              }
            
             </div>
           </div>
           
             <Modal isOpen={editModalToggle} toggle={handleEditRoleModal} centered={true}>
                <ModalHeader toggle={handleEditRoleModal}>Change User Role</ModalHeader>
                <ModalBody>
                  <FormControl component="fieldset" style={{width:'-webkit-fill-available'}}>
                    <FormLabel component="legend">Assign Role for : <span>{String(publicAddress).substring(0,5) + "....." + String(publicAddress).substring(String(publicAddress).length - 6, String(publicAddress).length - 1)}</span>  </FormLabel>
                    <FormGroup aria-label="position" row style={{alignItems:'center', justifyContent:'center'}}>
                    <span style={{color: !role ? "black" : "grey" }} >User</span>
                      <FormControlLabel
                        style={{marginLeft:'0'}}
                        value="start"
                        control={<Switch color="primary" />}
                        checked={role}
                        labelPlacement="start"
                        onChange={handleStatusSwitch}
                      /> <span style={{paddingLeft:'10px', color: role ? "black" : "grey" }}>Admin</span>
                    </FormGroup>
                  </FormControl>
                </ModalBody>
                <ModalFooter>
                <Button color="success" onClick={handleSubmit}>
                    Confirm
                </Button>
                <Button color="secondary" onClick={handleEditRoleModal}>
                    Cancel
                </Button>
                </ModalFooter>
            </Modal>
         </Fragment>
       
      );
};


export default Users;