
import type StorageDatasource from "../abstract/storage_datasource";
import TokenDatasource from "../abstract/token_datasource"

export default class TokenDatasourceImpl implements TokenDatasource {

    private _storageDatasource:StorageDatasource
    private _keyPrefix:string

    constructor(
        storageDatasource:StorageDatasource,
        keyPrefix:string = 'nft_')
        {
        this._storageDatasource = storageDatasource
        this._keyPrefix = keyPrefix
    }

    setStorageDatasource(storageDatasource:StorageDatasource) {
        this._storageDatasource = storageDatasource
    }

    setKeyPrefix(keyPrefix:string) {
        this._keyPrefix = keyPrefix
    }

    getToken(): string | null {
        return this._storageDatasource.read(`${this._keyPrefix}token`)
    }

    setToken(token: string): void {
        this._storageDatasource.write(`${this._keyPrefix}token`, token)
    }

    clearToken(): void {
        this._storageDatasource.delete(`${this._keyPrefix}token`)
    }

}
