import {
  SET_WALLET_ADDRESS_SUCCESS,
  SET_WALLET_ADDRESS_FAIL,
  SET_WALLET_CHAINID_SUCCESS,
  SET_WALLET_CHAINID_FAIL,
  SET_WALLET_BALANCE_SUCCESS,
  SET_WALLET_BALANCE_FAIL,
  SET_WALLET_INSTALLED_SUCCESS,
  SET_WALLET_INSTALLED_FAIL,
  SET_WALLET_CONNECTED_SUCCESS,
  SET_WALLET_CONNECTED_FAIL,
  CLEAR_ERRORS,
  } from "../../Constants/Wallet/index";
let  initialState = {
  wallet :{
    address: null,
    chainId: null,
    balance: null,
    isMetamaskInstalled: false,
    isConnected: false,
  }
    
}
  export const walletReducer = (state =initialState, action: { type: any; payload: any; }):any => {
    switch (action.type) {
      case SET_WALLET_ADDRESS_SUCCESS:
        return {
          wallet :{
            ...state.wallet,
            address: action.payload
          }
        };
      case SET_WALLET_CHAINID_SUCCESS:
        return {
          wallet :{
            ...state.wallet,
            chainId: action.payload
          }
        };
      case SET_WALLET_BALANCE_SUCCESS:
        return {
          wallet :{
            ...state.wallet,
            balance: action.payload
          }
        };
      case SET_WALLET_INSTALLED_SUCCESS:
        return {
          wallet :{
            ...state.wallet,
            isMetamaskInstalled: action.payload
          }
        };
      case SET_WALLET_CONNECTED_SUCCESS:
        return {
          wallet :{
            ...state.wallet,
            isConnected: action.payload
          }
        };
  
      case SET_WALLET_ADDRESS_FAIL:
        return {
          wallet :{
            ...state.wallet,
            address: null
          },
          error: action.payload
        };
      case SET_WALLET_CHAINID_FAIL:
        return {
          wallet :{
            ...state.wallet,
            chainId: null
          },
          error: action.payload
        };
  
      case SET_WALLET_BALANCE_FAIL:
        return {
          wallet :{
            ...state.wallet,
            balance: null
          },
          error: action.payload
        };
  
      case SET_WALLET_INSTALLED_FAIL:
        return {
          wallet :{
            ...state.wallet,
            isMetamaskInstalled: false
          },
          error: action.payload
        };
        case SET_WALLET_CONNECTED_FAIL:
          return {
            wallet :{
              ...state.wallet,
              isConnected: action.payload
            },
          error: action.payload
        };
      case CLEAR_ERRORS:
        return {
          ...state,
          error: null,
        };
  
      default:
        return state;
    }
  };
  
  
  