import Base from './Base'

export class Language extends Base {

    // ISO 639-1 規格に従う
    public static getResourceArray() {
        return [
            {
                id: 1,
                name: 'en',
                label: 'English'
            },
            {
                id: 2,
                name: 'ja',
                label: '日本語'
            }
        ]
    }

    public static english(): Language {
        return this.fromName('en')
    }

    public static japanese(): Language {
        return this.fromName('ja')
    }

    public isEnglish(): boolean {
        return (this.getName() === 'en')
    }

    public isJapanese(): boolean {
        return (this.getName() === 'ja')
    }
}