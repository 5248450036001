import React, {useEffect} from "react";
import { useSelector } from "react-redux";
import {Navigate,useLocation } from "react-router-dom";
import Loader from "../Layout/Loader/Loader";

const ProtectedRoute = ({ isAdmin, children }) => {
  const { loading, isAuthenticated, user } = useSelector((state) => state.user);
  const location = useLocation();
  // if(loading){
  //   return <Loader isLoading={true}/>
  // }

  // if(!isAuthenticated){
  //   return <Navigate to="/" state={{ from: location}} replace />
  // }
 
  // if(user.isAdmin ===undefined || user.isAdmin ===null){
  //   return <Loader isLoading={true}/>
  // }
  // if(user.isAdmin !=="true"){
  //   return <Navigate to="/" state={{ from: location}} replace />
  // }
  // return children;
  return loading || isAuthenticated ===undefined ?
          <Loader isLoading={ true }/> : 
        isAuthenticated ===false ?
         <Navigate to="/" />
         :
        
         user.isAdmin !== "true" ?
         <Navigate to="/" />
         :
         children
        
       
  
};

export default ProtectedRoute;
//return loading ===false ? isAuthenticated === false ? <Navigate to="/" />: isAdmin === true && user.isAdmin !== "true" ? <Navigate to="/" />: children : <Loader/>;
// loading? 
//         <Loader isLoading={loading ? true :false}/> : 