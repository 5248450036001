import User from "../../domain/modals/User"
import UserRepository from "src/domain/repositories/UserRepository"
import { Query } from "../../domain/repositories/userRepository/Query";
import UserDatasource from "../datasources/abstract/UserDatasource"
import UserDatasourceImpl from "../datasources/remote/UserDatasourceImpl"
import { UserJson } from "../jsons/UserJson";
import { Query as UserDatasourceQuery } from "../datasources/abstract/userDatasource/query"
import LoginSuccess from "../../domain/modals/LoginSuccess";
import { LoginSuccessJson } from "../jsons/LoginSuccessJson";
import LoginStatus from "../../domain/modals/LoginStatus";
import { LoginStatusJson } from "../jsons/LoginStatusJson";

export default class UserRepositoryImpl implements UserRepository {

    private userDatasource : UserDatasource;

    constructor () {
        this.userDatasource = new UserDatasourceImpl();
    }
    
    findById(id: string): Promise<User> {
        return new Promise((resolve, reject) => {
            this.userDatasource.findById(id).then((json: UserJson) => {
                resolve(User.fromJson(json))
            }).catch(e => {
                reject(e);
            })
        })
    }
    
    findByPublicAddress(publicAddress: string): Promise<User> {
        return new Promise((resolve, reject) => {
            this.userDatasource.findByPublicAddress(publicAddress).then((json: UserJson) => {
                resolve(User.fromJson(json))
            }).catch(e => {
                reject(e);
            })
        })
    }

    findByTwitterId(twitterId: string): Promise<User> {
        return new Promise((resolve, reject) => {
            this.userDatasource.findByTwitterId(twitterId).then((json: UserJson) => {
                resolve(User.fromJson(json))
            }).catch(e => {
                reject(e);
            })
        })
    }

    update(user: User): Promise<User> {
        return new Promise((resolve, reject) => {
            this.userDatasource.update(user.toUpdateJson()).then((json: UserJson) => {
                resolve(User.fromJson(json))
            }).catch(e => {
                reject(e);
            })
        })
    }

    existsByPublicAddress(publicAddress: string): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.userDatasource.existsByPublicAddress(publicAddress).then((result: boolean) => {
                resolve(result)
            }).catch(e => {
                reject(e);
            })
        })
    }

    search(query: Query): Promise<User[]> {
        return new Promise((resolve, reject) => {
            this.userDatasource.search(UserDatasourceQuery.fromJson(query.toJson)).then((jsons: UserJson[]) => {
                resolve(jsons.map((json) => {return User.fromJson(json)}))
            }).catch( e => {
                reject(e);
            })
        })
    }
    findAll(): Promise<User[]> {
        return new Promise((resolve, reject) => {
            this.userDatasource.findAll().then((jsons: UserJson[]) => {
                resolve(jsons.map((json) => {return User.fromJson(json)}))
            }).catch( e => {
                reject(e);
            })
        })
    }

    totalCount(query: Query): Promise<number> {
        return new Promise((resolve, reject) => {
            this.userDatasource.totalCount(UserDatasourceQuery.fromJson(query.toJson)).then(totalCount => {
                resolve(totalCount)
            }).catch( e => {
                reject(e);
            })
        })
    }

    create(user: User, signature:string): Promise<User> {
        return new Promise((resolve, reject) => {
            this.userDatasource.create(user.toCreateJson(),signature).then((json: UserJson) => {
                resolve(User.fromJson(json))
            }).catch(e => {
                reject(e);
            })
        })
    }

    me(): Promise<User> {
        return new Promise((resolve, reject) => {
            this.userDatasource.me().then((json: UserJson) => {
                resolve(User.fromJson(json))
            }).catch(e => {
                reject(e);
            })
        })
    }

    nonce(publicAddress: string): Promise<number> {
        return new Promise((resolve, reject) => {
            this.userDatasource.nonce(publicAddress).then((result: number) => {
                resolve(result)
            }).catch(e => {
                reject(e);
            })
        })
    }

    login(publicAddress: any, signature: string): Promise<LoginSuccess> {
        return new Promise((resolve, reject) => {
            this.userDatasource.login(publicAddress, signature).then((json: LoginSuccessJson) => {
                resolve(LoginSuccess.fromJson(json))
            }).catch(e => {
                reject(e);
            })
        })
    }

    loginStatus(): Promise<LoginStatus> {
        return new Promise((resolve, reject) => {
            this.userDatasource.loginStatus().then((json: LoginStatusJson) => {
                resolve(LoginStatus.fromJson(json))
            }).catch(e => {
                reject(e);
            })
        })
    }

    IsCurrentPublicAddressLoggedIn(publicAddress:string): Promise<boolean>{
        return new Promise((resolve, reject) => {
            this.userDatasource.IsCurrentPublicAddressLoggedIn(publicAddress).then((result:boolean) => {
                resolve(result)
            }).catch(e => {
                reject(e);
            })
        })
    }

    logout(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.userDatasource.logout().then((result: boolean) => {
                resolve(result)
            }).catch(e => {
                reject(e);
            })
        })
    }
}