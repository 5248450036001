import React, { useState } from 'react';
import { Accordion, AccordionItem, AccordionHeader, AccordionBody, Table } from 'reactstrap';

const NFTDetailsCard = ({nft}) => {
    const [open, setOpen] = useState('1');
    const toggle = (id) => {
      if (open === id) {
        setOpen();
      } else {
        setOpen(id);
      }
    };

    function createData(fieldName, value) {
        return { fieldName, value};
    }
      
      const rows = [
        createData('Contract Address', nft.contractAddress),
        createData('Token ID', nft.nftType.id),
        createData('Chain', 'Polygon'),
        createData('Token Standard', 'ERC-1155'),
        createData('Last Updated', nft.updatedAt),
      ];

  return (
    <Accordion open={open} toggle={toggle}>
      <AccordionItem>
        <AccordionHeader targetId="1">
          <h6 className="mb-0">Details</h6>
        </AccordionHeader>
        <AccordionBody accordionId='1'>
          <Table style={{overflowWrap: 'anywhere'}}>
            <tbody>
                {rows.map((row) => (
                    <tr>
                    <td>{row.fieldName}</td>
                    <td>{row.value}</td>
                  </tr>
            ))}
            </tbody>
          </Table>
        </AccordionBody>
      </AccordionItem>
    </Accordion>
  );
};

export default NFTDetailsCard;