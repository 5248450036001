import StorageDatasource from "../abstract/storage_datasource";

export default class LocalStorageDatasourceImpl implements StorageDatasource {

    read(key: string): string|null {
        return window.localStorage.getItem(key)
    }

    write(key: string, value: string): void {
        window.localStorage.setItem(key, value)
    }

    delete(key: string): void {
        window.localStorage.removeItem(key)
    }

    deleteAll(): void {
        window.localStorage.clear()
    }

}