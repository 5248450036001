import React, {Fragment,useEffect, useState } from 'react';
import Button  from '../../Elements/Buttons/Button';
import Sidebar from './Sidebar';
import styles from './MintNft.module.css'
import { Col, Form, FormGroup, Input, Label } from 'reactstrap';
import {NFTController} from 'src/controllers/NFTControllers';
import { TargetNFTType } from 'src/domain/value_objects/TargetNftType';
import { useDispatch, useSelector } from "react-redux";
import { clearErrors } from 'src/redux/Actions/User';
import Chain from 'src/domain/value_objects/Chain';
import PleaseWaitModal from 'src/components/Layout/Loader/PleaseWait';
import { ADD_SUPPLY_NFT_RESET } from 'src/redux/Constants/NFT';
import { toast } from 'react-toastify';

function MintMoreNFTs() {
    const dispatch =  useDispatch()

    const {mintAdditionalSupply} = NFTController()

     const {wallet,error}  = useSelector((state) => state.wallet)
     const {loading,success, error:nftAddMoreError }  = useSelector((state) => state.nftEditOrCreate)
    
    
    const [amount, setAmount] = useState("");
    const [targetNFTType, setTargetNFTType] = useState(TargetNFTType.getResourceArray()[0].label);
   

    const resetFormData = ()=>{
        setAmount(0);
        setTargetNFTType(TargetNFTType.getResourceArray()[0].label);
    }

    
    const handleSubmit = async(event) =>{
        event.preventDefault();

        if(wallet.chainId){
            const chain = Chain.fromId(wallet.chainId);
            if(!amount){
                toast.warning("Please enter an amount")
                return;
            }
            if(!chain || chain.isUnknown()){
                toast.warning("Invalid Network")
                return;
            }
            if( !targetNFTType){
                toast.warning("Please select the NFT type")
                return;
            }

            const nftType = TargetNFTType.fromLabel(targetNFTType)
            
            const result = await mintAdditionalSupply(chain.getName(),nftType.getName(),amount)
            
           if(result){
                resetFormData()
            }
            return;
        
        }else{
            toast.error("Chain id couldn't detected, please refresh to avoid error")
        }
    }

    useEffect(() => {
        if (error) {
            toast.error(error);
            dispatch(clearErrors());
        }
        if (nftAddMoreError) {
            toast.error(nftAddMoreError);
            dispatch(clearErrors());
        }
        if (success) {
            toast.success("Supply Added Successfully");
            dispatch(clearErrors());
            dispatch({ type: ADD_SUPPLY_NFT_RESET });
        }
    }, [dispatch, error,nftAddMoreError,success])

  return (
    <Fragment>
        <div className={styles.dashboard}>
            <Sidebar  />
            <div className={styles.adminListContainer}>
              <h1 id={styles.adminListHeading}>Mint more NFTs</h1>
              <Form className={styles.form} >
                    <FormGroup col>
                        <Label for="type" sm={2}>
                         NFT Type 
                        </Label>
                        <Col sm={10}>
                            <Input
                                required={true}
                                name="select"
                                type="select"
                                className={styles.text}
                                value= {targetNFTType}
                                onChange={(e) => setTargetNFTType(e.target.value)}
                            >
                               {
                                    TargetNFTType.getResourceArray().map((item)=>(
                                        <option>
                                          {item.label}
                                       </option>
                                    ))
                                }
                            </Input>
                        </Col>
                    </FormGroup>
                    <FormGroup col>
                        <Label for="id" sm={2}>
                          Amount
                        </Label>
                        <Col sm={10}>
                            <Input
                                required={true}
                                id="amount"
                                name="Amount"
                                type="text"
                                //onWheel={handleWheel}
                                className={styles.number}
                                value={amount}
                                onChange={(e) => {
                                    const regex = /^[0-9\b]+$/; // regular expression to allow only digits
                                    const value = e.target.value
                                    if (value === '' || regex.test(value)) {
                                        setAmount(value)
                                    }
                                    // if(e.target.value<0){
                                    //     setAmount(0)
                                    // }else{
                                    //     setAmount(e.target.value)
                                    // }
                                    
                                }}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup check row>
                        <Col sm={{offset: 8, size: 2}}>
                            <Button variant='contained' onClick={handleSubmit}>Mint more</Button>
                        </Col>
                    </FormGroup>
                </Form>
              
            </div>
        </div>
        <PleaseWaitModal showModal={loading? true : false}/>
    </Fragment>
  );
}
export default MintMoreNFTs;

