import { createStore, combineReducers,compose, applyMiddleware } from "redux";
import thunk from "redux-thunk";
//import { composeWithDevTools } from "redux-devtools-extension";
import {
  allUsersReducer,
  profileReducer,
  userReducer,
  userDetailsReducer,
  userLoggInStatusReducer,
  userSearchReducer,
} from "./redux/reducers/User/index";
import {
  walletReducer
} from "./redux/reducers/Wallet/index";
import { fetchAllNFTReducer,
  nftLinkReducer,
  nftReducer,
 } from "./redux/reducers/NFT";


const reducer = combineReducers({
  user: userReducer,
  profile: profileReducer,
  users: allUsersReducer,
  wallet : walletReducer,
  nfts : fetchAllNFTReducer,
  userDetails :userDetailsReducer,
  loginStatus: userLoggInStatusReducer,
  userSearch : userSearchReducer,
  nftEditOrCreate :nftReducer,
  nftLinkOrQr: nftLinkReducer
});

let initialState = {
  
};

const middleware = [thunk];

const store = createStore(
  reducer,
  compose(applyMiddleware(thunk))
);

export default store;
