import React, { useState } from 'react';
import { Accordion, AccordionItem, AccordionHeader, AccordionBody, Table } from 'reactstrap';
import styles from './already_claimed_nfts.module.css';

const AlreadyClaimedNFTs = ({nfts}) => {
    const [open, setOpen] = useState('1');
    const toggle = (id) => {
      if (open === id) {
        setOpen();
      } else {
        setOpen(id);
      }
    };

    function createData(Sno, name, id, type) {
        return {Sno, name, id, type};
    }

    const rows = [];
    nfts && nfts.forEach((item) => {
      rows.push(
        createData(rows.length, item.tokenName, item.id,item.nftType.label)
      );
    });
  return (
    <Accordion open={open} toggle={toggle}>
      <AccordionItem>
        <AccordionHeader targetId="1">
          <h6 className={`mb-0 ${styles.tableHeading}`} >Already Claimed NFTs</h6>
        </AccordionHeader>
        <AccordionBody accordionId='1' className={styles.accordionBody}>
          {rows.length> 0 ? 
          <Table>
            <thead>
              <tr>
                <th>S.no.</th>
                <th>Name</th>
                <th>ID</th>
                <th>Type</th>
              </tr>
            </thead>
            <tbody>
              {rows.map((row) => (
                  <tr>
                    <td>{row.Sno}</td>
                    <td>{row.name}</td>
                    <td>{row.id}</td>
                    <td>{row.type}</td>
                  </tr>
            ))}
            </tbody>
            
          </Table> 
          :
          <div className={styles.noData}>
          No NFT data to show!
        </div>
        }
        </AccordionBody>
      </AccordionItem>
    </Accordion>
  );
};

export default AlreadyClaimedNFTs;