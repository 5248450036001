export default class DataToSignGenerator {
    public static general(nonce: string): string {
        return `Welcome to CryptoLoungeGOX's Slot NFT.
        To claim the NFT, please sign the message.
        After that, press the claim button.

Nonce:
${nonce}`;
    }

    /**
     * sign a message to verify public address
     * @returns 
     */
    public static register(): string {
        return `Welcome to CryptoLoungeGOX's Slot NFT.
        Please, sign this message to verify your public address`;
    }
}
