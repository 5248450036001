import UserRepositoryImpl from "src/data/repositories/UserRepositoryImpl";
import LoginStatus from "src/domain/modals/LoginStatus";
import LoginSuccess from "src/domain/modals/LoginSuccess";
import User from "src/domain/modals/User";
import { useDispatch, useSelector } from "react-redux";
import UserRepository from "src/domain/repositories/UserRepository";
import { Query } from "src/domain/repositories/userRepository/Query";
import TokenDatasource from "src/data/datasources/abstract/token_datasource";
import TokenDatasourceImpl from "src/data/datasources/local/token_datasource_impl";
import LocalStorageDatasourceImpl from "src/data/datasources/local/local_storage_datasource_impl";
import BaseError from "src/errors/BaseError";
import { Dispatch } from "react";
import { ALL_USERS_FAIL, ALL_USERS_REQUEST, ALL_USERS_SUCCESS, LOAD_USER_FAIL, LOAD_USER_REQUEST, LOAD_USER_SUCCESS, LOGIN_FAIL, LOGIN_REQUEST, LOGIN_STATUS_REQUEST, LOGIN_STATUS_SUCCESS, LOGIN_SUCCESS, LOGOUT_FAIL, LOGOUT_SUCCESS, REGISTER_USER_FAIL, REGISTER_USER_REQUEST, REGISTER_USER_SUCCESS, UPDATE_USER_FAIL, UPDATE_USER_REQUEST, UPDATE_USER_SUCCESS, USER_DETAILS_FAIL, USER_DETAILS_REQUEST, USER_DETAILS_SUCCESS, USER_SEARCH_FAIL, USER_SEARCH_REQUEST, USER_SEARCH_SUCCESS } from "src/redux/Constants/User";
import { toast } from 'react-toastify';

export function UserController() {
    const userRepository : UserRepository = new UserRepositoryImpl();
    const tokenDatasourceImpl :TokenDatasource = new TokenDatasourceImpl(new LocalStorageDatasourceImpl());
    const dispatch = useDispatch();
    const {user:loggedInUser} = useSelector((state:any) => state.user)
    
    const UserFindById = async (id: string) => {
        try {
            const user: User = await userRepository.findById(id);
            return user;
        } catch (e) {
            let err;
            if(e instanceof BaseError){
                err = e.getErrors()
                err = err[0]
            }else{
                err = e
            }
            toast.error(err as any)
        }
    }
    
    const UserFindByPublicAddress = async (publicAddess: string) => {
        try {
            dispatch({type:USER_DETAILS_REQUEST})
            const user: User = await userRepository.findByPublicAddress(publicAddess);
            dispatch({type:USER_DETAILS_SUCCESS,payload:user})
            return user;
        } catch (e) {
            let err;
            if(e instanceof BaseError){
                err = e.getErrors()
                err = err[0]
            }else{
                err = e
            }
            dispatch({type:USER_DETAILS_FAIL,payload:err})
        }
    }
    
    const UserFindByTwitterId = async (twitterId: string) => {
        try {
            const user: User = await userRepository.findByTwitterId(twitterId);
            return user;
        } catch (e) {
            let err;
            if(e instanceof BaseError){
                err = e.getErrors()
                err = err[0]
            }else{
                err = e
            }
            toast.error(err as any)
        }
    }

    const UserUpdate = async (userEntity: User) => {
        try {
            const user: User = await userRepository.update(userEntity);
            return user;
        } catch (e) {
            let err;
            if(e instanceof BaseError){
                err = e.getErrors()
                err = err[0]
            }else{
                err = e
            }
        }
    }

    const UserUpdateRole = async (userEntity: User) => {
        try {
            dispatch({type:UPDATE_USER_REQUEST})
            const user: User = await userRepository.update(userEntity);
            dispatch({type:UPDATE_USER_SUCCESS,payload:user})
            return user;
        } catch (e) {
            let err;
            if(e instanceof BaseError){
                err = e.getErrors()
                err = err[0]
            }else{
                err = e
            }
            dispatch({type:UPDATE_USER_FAIL,payload:err})
        }
    }
    
    const UserExists = async (publicAddress: string) => {
        try {
            const result: boolean = await userRepository.existsByPublicAddress(publicAddress);
            return result;
        } catch (e) {
            let err;
            if(e instanceof BaseError){
                err = e.getErrors()
                err = err[0]
            }else{
                err = e
            }
            toast.error(err as any)
        }
    }

    const UserSearch = async (params: any) => {
        try {
            const query = new Query();
            dispatch({ type: USER_SEARCH_REQUEST });
            if(params) {
                if (params['id'] !== undefined) {
                    query.id = params['id']
                }
                if (params['publicAddress'] !== undefined) {
                    query.publicAddress = params['publicAddress']
                }
                if (params['twitterId'] !== undefined) {
                    query.twitterId = params['twitterId']
                }
                if (params['limit'] !== undefined) {
                    query.limit = params['limit']
                }
                if (params['maxCreatedAt'] !== undefined) {
                    query.maxCreatedAt = params['maxCreatedAt']
                }
                if (params['minCreatedAt'] !== undefined) {
                    query.minCreatedAt = params['minCreatedAt']
                }
                if (params['offset'] !== undefined) {
                    query.offset = params['offset']
                }
                if (params['sortBy'] !== undefined) {
                    query.sortBy = params['sortBy']
                }
            }
            const users: User[] = await userRepository.search(query);
            dispatch({ type: USER_SEARCH_SUCCESS, payload:users });
            return users;
        } catch (e) {
            let err;
            if(e instanceof BaseError){
                err = e.getErrors()
                err = err[0]
            }else{
                err = e
            }
            dispatch({ type: USER_SEARCH_FAIL, payload:err });
        }
    }
    
    const UserFindAll = async () => {
        try {
            dispatch({ type: ALL_USERS_REQUEST });
            const users: User[] = await userRepository.findAll();
            dispatch({ type: ALL_USERS_SUCCESS, payload: users });
            return users;
        } catch (e) {
            let err;
            if(e instanceof BaseError){
                err = e.getErrors()
                err = err[0]
            }else{
                err = e
            }
            dispatch({ type: ALL_USERS_FAIL, payload: err });
            
        }
    }
    const UserTotalCount = async (params: any) => {
        try {
            const query = new Query();
            if(params) {
                if (params['id'] !== undefined) {
                    query.id = params['id']
                }
                if (params['publicAddress'] !== undefined) {
                    query.publicAddress = params['publicAddress']
                }
                if (params['limit'] !== undefined) {
                    query.limit = params['limit']
                }
                if (params['maxCreatedAt'] !== undefined) {
                    query.maxCreatedAt = params['maxCreatedAt']
                }
                if (params['minCreatedAt'] !== undefined) {
                    query.minCreatedAt = params['minCreatedAt']
                }
                if (params['offset'] !== undefined) {
                    query.offset = params['offset']
                }
                if (params['sortBy'] !== undefined) {
                    query.sortBy = params['sortBy']
                }
            }
            const totalCount: number = await userRepository.totalCount(query);
            return totalCount;
        } catch (e) {
            let err;
            if(e instanceof BaseError){
                err = e.getErrors()
                err = err[0]
            }else{
                err = e
            }
            toast.error(err as any)
        }
    }

    const UserCreate = async (userEntity: User,signature:string) => {

        try {
            if(loggedInUser && loggedInUser.isAdmin!=="true"){
                dispatch({type:REGISTER_USER_REQUEST})
            }
            const user: User = await userRepository.create(userEntity,signature);
            if(loggedInUser &&loggedInUser.isAdmin!=="true"){
                dispatch({type:REGISTER_USER_SUCCESS})
            }
                return user;
        } catch (e) {
            if(loggedInUser &&loggedInUser.isAdmin!=="true"){
                dispatch({type:REGISTER_USER_FAIL})
            }
            let err;
            if(e instanceof BaseError){
                err = e.getErrors()
                err = err[0]
            }else{
                err = e
            }
            toast.error(err as any);
        }
    }

    const UserMe = async () => {
        try {
            dispatch({ type: LOAD_USER_REQUEST });
            const user: User = await userRepository.me();
            dispatch({ type: LOAD_USER_SUCCESS, payload: user });
            return user;
        } catch (e) {
            let err;
            if(e instanceof BaseError){
                err = e.getErrors()
                err = err[0]
            }else{
                err = e
            }
            dispatch({type: LOAD_USER_FAIL,payload: err,})
            
        }
    }

    const UserNonce = async (publicAddress: string) => {
        try {
            const nonce: number = await userRepository.nonce(publicAddress);
            return nonce;
        } catch (e) {
            let err;
            if(e instanceof BaseError){
                err = e.getErrors()
                err = err[0]
            }else{
                err = e
            }
            toast.error(err as any)
        }
    }

    const UserLogin = async (publicAddress: string, signature: string) => {
        try {
            dispatch({type: LOGIN_REQUEST})
            const loginSuccess: LoginSuccess = await userRepository.login(publicAddress, signature);
            
            tokenDatasourceImpl.setToken(loginSuccess.token)
            dispatch({type: LOGIN_SUCCESS})
            return loginSuccess;
        } catch (e) {
            dispatch({type: LOGIN_FAIL})
            let err;
            if(e instanceof BaseError){
                err = e.getErrors()
                err = err[0]
            }else{
                err = e
            }
            toast.error(err as any);
        }
    }

    const UserLoginStatus = async () => {
        try {
            dispatch({type: LOGIN_STATUS_REQUEST})
            const loginStatus: LoginStatus = await userRepository.loginStatus();
            dispatch({type: LOGIN_STATUS_SUCCESS})
            return loginStatus;
        } catch (e) {
            let err;
            if(e instanceof BaseError){
                err = e.getErrors()
                err = err[0]
            }else{
                err = e
            }
            dispatch({type: LOGIN_STATUS_SUCCESS, payload:err})
            toast.error(err as any)
        }
    }
    
    const IsUserCurrentPublicAddressLoggedIn = async (publicAddress:string) => {
        try {
            const result:boolean = await userRepository.IsCurrentPublicAddressLoggedIn(publicAddress);
            return result
        } catch (e) {
            let err;
            if(e instanceof BaseError){
                err = e.getErrors()
                err = err[0]
            }else{
                err = e
            }
            toast.error(err as any)
        }
    }

    const UserLogout = async () => {
        try {
            const result: boolean = await userRepository.logout();
            tokenDatasourceImpl.clearToken();
            dispatch({type:LOGOUT_SUCCESS})
            return result;
        } catch (e) {
            dispatch({type: LOGOUT_FAIL})
            let err;
            if(e instanceof BaseError){
                err = e.getErrors()
                err = err[0]
            }else{
                err = e
            }
            toast.error(err as any)
        }
    }
    
    return {
        UserFindById,
        UserFindByPublicAddress,
        UserFindByTwitterId,
        UserUpdate,
        UserUpdateRole,
        UserExists,
        UserSearch,
        UserFindAll,
        UserTotalCount,
        UserCreate,
        UserMe,
        UserNonce,
        UserLogin,
        UserLoginStatus,
        IsUserCurrentPublicAddressLoggedIn,
        UserLogout
    }
}
