import React from 'react'
import { Navbar,NavbarBrand,} from 'reactstrap';
import styles from "./Header.module.css"
import gox_logo from '../../../assets/images/GOX_logo.png';
import WalletButton from '../../Elements/Buttons/WalletButton';
import Grow from '@mui/material/Grow';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import MenuList from '@mui/material/MenuList';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useMetamask } from 'src/Service/web3';
function Header() {
    const Navigate = useNavigate();
    const {disconnect} = useMetamask()

    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
  
    const {user, isAuthenticated} = useSelector(state => state.user)
    const {wallet} = useSelector(state => state.wallet)

    const handleClose = (event) => {
      if (anchorRef.current && anchorRef.current.contains(event.target)) {
        return;
      }
      setOpen(false);
    };
  
    function handleListKeyDown(event) {
      if (event.key === 'Tab') {
        event.preventDefault();
        setOpen(false);
      } else if (event.key === 'Escape') {
        setOpen(false);
      }
    }
  
    const options = [];

    if(isAuthenticated){
        options.push({name:"About Us", function: about});
        options.push({name:"Contact Us", function: contact});
        options.push({name:"Logout", function: logout});
    }
    if (user && user?.isAdmin ==="true" ) {
        options.unshift({name:"Dashboard", function: dashboard});
      }
    
    function dashboard() {
        Navigate("/admin/users");
    }

    function about() {
        window.open("https://cryptoloungegox.com/#:~:text=event%20list-,ABOUT,-About%20Crypto%20Lounge");
      }
      function contact() {
        window.open("https://t2web.co.jp/contact/");
    }
    async function logout() {
      await disconnect()
      
    }

    // function onMouseEnterOnWalletButton(){
    //   if(isAuthenticated){
    //     setOpen((prevOpen) => !prevOpen);
    //   }
    // }
    function handleToggle(){
      if(isAuthenticated){
        setOpen((prevOpen) => !prevOpen);
      }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
      if (prevOpen.current === true && open === false) {
        //anchorRef.current.focus();
      }
  
      prevOpen.current = open;
    }, [open]);

    return (
        <Navbar color="black" className={styles.sticky} light expand="md">
            <NavbarBrand href="/"><img className={styles.logo} src={gox_logo}/></NavbarBrand> 
            <div
              ref={anchorRef}
              id="composition-button"
              aria-controls={open ? 'composition-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-haspopup="true"
              onClick={handleToggle}
              //onMouseEnter={onMouseEnterOnWalletButton} 
            >
             <WalletButton isHeader={true} /> 
            </div>
            
            <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                placement="bottom-start"
                transition
                disablePortal
                >
                {({ TransitionProps, placement }) => (
                    <Grow
                    {...TransitionProps}
                    style={{
                        transformOrigin:
                        placement === 'bottom-start' ? 'left top' : 'left bottom',
                    }}
                    >
                    <Paper style={{marginTop:10}}>
                        <ClickAwayListener onClickAway={handleClose}>
                        <MenuList
                            //autoFocusItem={open}
                            id="composition-menu"
                            aria-labelledby="composition-button"
                            onKeyDown={handleListKeyDown}
                        >
                            {options.map((option)=>(
                                <MenuItem key={option.name} style={{paddingLeft: 30, paddingRight: 30}} onClick={(event)=>{handleClose(event); option.function()}}>{option.name}</MenuItem>
                            ))}
                        </MenuList>
                        </ClickAwayListener>
                    </Paper>
                    </Grow>
                )}
            </Popper>
    </Navbar>
    )
}

export default Header