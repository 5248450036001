import React, { useState } from 'react';
import { Accordion, AccordionItem, AccordionHeader, AccordionBody, Table } from 'reactstrap';

function DescriptionCard({nft}) {
  const [open, setOpen] = useState('1');
  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  return (
    <Accordion open={open} toggle={toggle}>
      <AccordionItem>
        <AccordionHeader targetId="1">
          <h6 className="mb-0">Description</h6>
        </AccordionHeader>
        <AccordionBody accordionId='1'>
          {nft.description}
        </AccordionBody>
      </AccordionItem>
    </Accordion>
  );
}

export default DescriptionCard;