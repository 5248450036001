import styles from './NFTImagesideCard.module.css'
import { Row, Col } from 'reactstrap';
import DescriptionCard from '../../Elements/DescriptionCard';
import { useEffect, useState } from 'react';
function NFTImagesideCard({nft}) {

  return (
    <div className={styles.basicDetails}>
    <div className={styles.basicHeadings}>
    <Row>
      <Col className={styles.nftName}>
        {nft.tokenName}
      </Col>
    </Row >
    <Row>
      <Col className={styles.owner}>
        Owned By {String(nft.owner).substring(0,5) + "....." + String(nft.owner).substring(String(nft.owner).length - 6, String(nft.owner).length - 1)}
      </Col>
    </Row>
    </div>
    <Row>
      <Col >
        <DescriptionCard nft={nft}/>
      </Col>
    </Row>
    </div>
  );
}

export default NFTImagesideCard;