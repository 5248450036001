import {Dispatch } from 'react'
import {ethers}  from 'ethers'
import Web3 from 'web3'
import detectEthereumProvider from '@metamask/detect-provider' // This function detects most providers injected at window.ethereum
import MetaMaskOnboarding from '@metamask/onboarding'
import DataToSignGenerator  from 'src/Utils/DataToSignGenerator'
import { useDispatch, useSelector } from "react-redux";
import {setAddress, setBalance,setChainId,setIsConnected,setIsMetamaskInstalled} from "../redux/Actions/Wallet/index";

import User from 'src/domain/modals/User'
import {UserController} from 'src/controllers/UserControllers';
import { Language } from 'src/domain/value_objects/Language'
import { toast } from 'react-toastify'
declare global {
  interface Window {
    ethereum: any
  }
}

export function useMetamask() {
  const {UserExists, UserLoginStatus, UserCreate,UserNonce, UserLogin, UserLogout} = UserController()
  const dispatch:Dispatch<any>= useDispatch();
  let ethereum: any = null //window.ethereum,
  let provider: any = null
  let web3: Web3 ;
  const { wallet } = useSelector((state:any) => state.wallet);

  const initializeProvider = async():Promise<void> => {
    if(wallet.isMetamaskInstalled) {
      ethereum = window.ethereum;
      web3 = new Web3(ethereum);
      provider = new ethers.BrowserProvider(ethereum)
    }
    
  }
 
  /**
   * to call this function first call
   * initializeProvider()
   * @returns 
   */
  const checkIfConnected = async () => { 
    let accounts : any =[];
    try {
      accounts = await web3.eth.getAccounts();
    } catch(e) {
        // console.log(e)
    }
    if(accounts.length > 0) {
      dispatch(setIsConnected(true))
      //dispatch(setAddress(accounts[0])) //don't set address here
      return true;
    }
    dispatch(setIsConnected(false))
    return false;
  }

  const setWalletAddress = async () => { 
    await initializeProvider()
    let accounts : any =[];
    try {
      accounts = await web3.eth.getAccounts();
    } catch(e) {
        // console.log(e)
    }
    if(accounts.length > 0) {
      dispatch(setAddress(accounts[0]))
    }
  }

  const connectWallet = async () => {
    await initializeProvider();
    let accounts: any[] = []
    if (!ethereum) {
      dispatch(setIsMetamaskInstalled(false))
      alert("Install Metamask First")
      return
    }
    if (wallet.isConnected) {
      accounts = await web3.eth.getAccounts();
    } else {
      try {
        accounts = await ethereum.request({
        method: 'eth_requestAccounts'
        })
      } catch (err) {
        await ethereum.request({
         method: 'wallet_requestPermissions',
        })
        accounts = await ethereum.request({
         method: 'eth_requestAccounts',
        })
      }
    }

    if(accounts.length>0){
      dispatch(setIsConnected(true))
      //dispatch(setAddress(accounts[0]))
    }
   
    return accounts[0]
  }

  const sign = async (nonce: string, publicAddress: string) => {
    const templateMessage = DataToSignGenerator.general(nonce)
    const provider = await detectEthereumProvider()
    if (provider) {
      // From now on, this should always be true:
      // provider === window.ethereum
      const web3 = new Web3(<any>provider)
      const signature = await web3.eth.personal.sign(
        templateMessage,
        publicAddress,
        '' // no password 
      )

      const result = await UserLogin(publicAddress, signature)
      if(result){
        await checkBalance(publicAddress)
        await checkAddress(publicAddress)
        dispatch(setIsConnected(true))
      }
    } else {
      toast.info('Please install MetaMask!')
    }
  }

  const disconnect = async () => {
    // initializeProvider();
    const result = await UserLoginStatus();
    if(result){
      if(result.isLoggedIn ===true){
        const s = await UserLogout()
        if(s){
          await checkAddress(null)
          dispatch(setBalance(null))
          return true;
        }
        return false;
      }
      await checkAddress(null)
      dispatch(setBalance(null))
      return true;
    }else{
     return false;
    }
  
  }

  const checkAddress = async (address:string| null) => {
    dispatch(setAddress(address))
  }

  const accountChanged = async (publicAddress:string) => {

    let result= await UserExists(publicAddress)
    let newUser;
    if(!result ){
      let language =  new Language({id : 1, name:"en", label:'English'});
      newUser = new User(
        null,publicAddress,null,null,language,false,"false",null,null,null
      )
      const templateMessage = DataToSignGenerator.register()
      const provider = await detectEthereumProvider()
      if (provider) {
        // From now on, this should always be true:
        // provider === window.ethereum
        const web3 = new Web3(<any>provider)
        const signature = await web3.eth.personal.sign(
          templateMessage,
          publicAddress,
          '' // no password 
        )

        newUser = await UserCreate(newUser,signature)
        if(newUser){
          const nonce  = await UserNonce(newUser!.publicAddress);
    
          if(nonce){
              await sign(String(nonce), newUser!.publicAddress)
          }
        }
       
        return;
      }
      else {
        toast.info('Please install MetaMask!')
      }
      
    }else{
      const nonce  = await UserNonce(publicAddress);
  
      if(nonce){
          await sign(String(nonce), publicAddress)
      }
    }
    
  }

  const checkChainId = async () => {
    let ethereum = window.ethereum
    if (ethereum) {
      let chainId = await window.ethereum.request({
        method: 'eth_chainId',
      })
      chainId = parseInt(chainId,16)
      dispatch(setChainId(chainId))
     
    }
  }

  const checkBalance = async (address: string| null) => {
    let balanceEth = ''
    if(address && provider) {
      let balance = await provider.getBalance(address)
      balanceEth = ethers.formatEther(balance)  
    }
    dispatch(setBalance(balanceEth))
  }

  const checkMetamaskAvailability = async () => {
    let ethereum = window.ethereum
    const isMetamaskInstalled = MetaMaskOnboarding.isMetaMaskInstalled()
    if (!ethereum || !isMetamaskInstalled) {
      dispatch(setIsMetamaskInstalled(false))
    } else {
        dispatch(setIsMetamaskInstalled(true))
        await checkChainId();
        // if(!ethereum._events.accountsChanged) {
        //   ethereum.on('accountsChanged', accountChanged)
        // }
      // initializeProvider();
    }
  }

  return {
    checkMetamaskAvailability,
    connectWallet,
    setWalletAddress,
    sign,
    disconnect,
    checkBalance,
    checkAddress,
    accountChanged,
    checkIfConnected,
    initializeProvider
  }
}
