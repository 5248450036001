import { LoginSuccessJson } from '../../data/jsons/LoginSuccessJson'
import { DateTime } from 'luxon'
import { BaseModel } from './BaseModel'

export default class LoginSuccess implements BaseModel {

  public static fromJson(json:LoginSuccessJson): LoginSuccess {

    return new this(
      json.type,
      json.token,
      json.tokenHash,
      DateTime.fromISO(json.expiresAt!)
    )
  }

  constructor(
    public type: string,
    public token: string,
    public tokenHash: string,
    public expiresAt: DateTime
  ){}

  public toJson(): LoginSuccessJson {
    return {
      type: this.type,
      token: this.token,
      tokenHash: this.tokenHash,
      expiresAt: this.expiresAt.toISO()
    }
  }
}
