import { DateTime } from 'luxon'
import { BaseModel } from './BaseModel'
import { UserJson } from '../../data/jsons/UserJson'
import { UserUpdateJson } from '../../data/jsons/user/UserUpdateJson'
import { NFTJson } from '../../data/jsons/NFTJson'
import { Language } from '../value_objects/Language'
import { UserCreateJson } from '../../data/jsons/user/UserCreateJson'

export default class User implements BaseModel {

public static fromJson(json:UserJson): User {
    
    if (json.language.id === undefined) {
        throw new Error(`language id is undefined`)
    }
    const language = Language.fromId<Language>(json.language.id)
    if (language.isUnknown()) {
        throw new Error(`LanguageId is invalid: ${json.language.id}`)
    }

    return new this(
    json.id,
    json.publicAddress,
    json.twitterId,
    json.nfts,
    language,
    json.isAdmin,
    json.rememberMeToken,
    (json.lastAccessedAt !== null) ? DateTime.fromISO(json.lastAccessedAt) : null,
    DateTime.fromISO(json.createdAt!),
    DateTime.fromISO(json.updatedAt!)
    )
}

constructor(
    public id: string | null, 
    public publicAddress: string,
    public twitterId: string |null,
    public nfts: NFTJson[]|null,
    public Language: Language,
    public isAdmin: boolean |null,
    public rememberMeToken: string |null,
    public lastAccessedAt: DateTime|null,
    public createdAt: DateTime | null,
    public updatedAt: DateTime | null
){}

public toJson(): UserJson {
    return {
    id: this.id,
    publicAddress: this.publicAddress,
    twitterId: this.twitterId,
    nfts: this.nfts,
    language: this.Language,
    isAdmin: this.isAdmin,
    rememberMeToken: this.rememberMeToken,
    lastAccessedAt: (this.lastAccessedAt !== null) ? this.lastAccessedAt.toISO() : null,
    createdAt: this.createdAt!.toISO(),
    updatedAt: this.updatedAt!.toISO()
    }
}

public toUpdateJson(): UserUpdateJson {
    if (this.id === undefined) {
    throw new Error('id must be specified to update')
    }

    return {
        id: this.id!,
        twitterId: this.twitterId!,
        isAdmin: this.isAdmin!,
        rememberMeToken: this.rememberMeToken!,
    }
}

public toCreateJson(): UserCreateJson {
    // if (this.id === undefined) {
    // throw new Error('id must be specified to update')
    // }

    return {
        publicAddress: this.publicAddress,
        twitterId: this.twitterId,
        rememberMeToken: this.rememberMeToken,
    }
}
}
