export const FETCH_NFTS_REQUEST = "FETCH_NFTS_REQUEST"
export const LOAD_NFT_DATA_FAIL = "LOAD_NFT_DATA_FAIL"

export const FETCH_NFTS_SUCCESS = "FETCH_NFTS_SUCCESS"
export const FETCH_NFTS_FAIL = "FETCH_NFTS_FAIL"

export const FETCH_NFTS_COUNT_SUCCESS = "FETCH_NFTS_COUNT_SUCCESS"
export const FETCH_NFTS_COUNT_FAIL = "FETCH_NFTS_COUNT_FAIL"

export const CREATE_NFT_REQUEST = "CREATE_NFT_REQUEST"
export const CREATE_NFT_SUCCESS = "CREATE_NFT_SUCCESS"
export const CREATE_NFT_FAIL = "CREATE_NFT_FAIL"
export const CREATE_NFT_RESET = "CREATE_NFT_RESET"

export const ADD_SUPPLY_NFT_REQUEST = "ADD_SUPPLY_NFT_REQUEST"
export const ADD_SUPPLY_NFT_SUCCESS = "ADD_SUPPLY_NFT_SUCCESS"
export const ADD_SUPPLY_NFT_FAIL = "ADD_SUPPLY_NFT_FAIL"
export const ADD_SUPPLY_NFT_RESET = "ADD_SUPPLY_NFT_RESET"

export const CLAIM_NFT_REQUEST = "CLAIM_NFT_REQUEST"
export const CLAIM_NFT_SUCCESS = "CLAIM_NFT_SUCCESS"
export const CLAIM_NFT_FAIL = "CLAIM_NFT_FAIL"
export const CLAIM_NFT_RESET = "CLAIM_NFT_RESET"

export const NFT_LINK_REQUEST = "NFT_LINK_REQUEST"
export const NFT_LINK_SUCCESS = "NFT_LINK_SUCCESS"
export const NFT_LINK_FAIL = "NFT_LINK_FAIL"

export const CLEAR_ERRORS = "CLEAR_ERRORS";
