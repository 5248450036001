import React, {Fragment, useEffect, useState } from 'react';
import Button  from '../../Elements/Buttons/Button';
import Sidebar from './Sidebar';
import styles from './MintNft.module.css'
import { Col, Form, FormGroup, Input, Label } from 'reactstrap';
import { NFTController } from 'src/controllers/NFTControllers';
import { TargetNFTType } from 'src/domain/value_objects/TargetNftType';
import Chain from 'src/domain/value_objects/Chain';
import { useDispatch, useSelector } from 'react-redux';
import { clearErrors } from 'src/redux/Actions/User';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { toast } from 'react-toastify';


function DownloadNFTQR() {
  const dispatch =  useDispatch()

    const {downloadQRCodes} = NFTController();
    const [targetNFTType, setTargetNFTType] = useState(TargetNFTType.getResourceArray()[0].label);
    const [amount, setAmount] = useState('');
    const {wallet,error}  = useSelector((state) => state.wallet)
    const {loading, error: targetNotReceived}  = useSelector((state) => state.nftLinkOrQr)
    const [password, setPasswrd] = useState('')
    const [passwordPrompt, setPasswordPrompt] = useState(false)
  
    const resetFormData = ()=>{
        setAmount(0);
        setTargetNFTType(TargetNFTType.getResourceArray()[0].label);
    }

    const togglePrompt = () => {
        if(document.getElementById("amount").value === '') {
            toast.warning("Please provide some amount")
        } else if(document.getElementById("amount").value < 0) {
            toast.warning("Please provide a valid amount")
        } else {
            setPasswordPrompt(!passwordPrompt);
        }
    }
    
    const handleBrowserDefault = (event) => {
        event.preventDefault();
        togglePrompt();
    }
    
    const handleSubmit = async (event) => {
        setPasswordPrompt(false);
        event.preventDefault();
        if(wallet.chainId){
            const chain = Chain.fromId(wallet.chainId);
            const nftType = TargetNFTType.fromLabel(targetNFTType)

            if(!chain || chain.isUnknown()){
                toast.warning("Invalid Network")
                return;
            }
            if( !targetNFTType || nftType.isUnknown()){
                toast.warning("Please select the valid NFT type")
                return;
            }
            const {result, errorOrigin} = await downloadQRCodes(chain.getName(), nftType.getName(), amount, document.getElementById("password").value);
            if(result){
                resetFormData()
            } else if(errorOrigin === 'frontend'){
                toast.error("Sorry! your password is incorrect");
            }
            return;
        }else{
            toast.error("Chain id couldn't detected, please refresh to avoid error")
        }
  }

  useEffect(() => {
    if (error) {
        toast.error(error);
        dispatch(clearErrors());
    }
    if (targetNotReceived) {
        toast.error(targetNotReceived);
        dispatch(clearErrors());
    }
}, [dispatch, error, targetNotReceived])

  return (
    <Fragment>
        <Dialog open={passwordPrompt}>
            <DialogTitle>Enter Password</DialogTitle>
            <DialogContent>
            <DialogContentText>
                Please Enter a password to Download QRs.
            </DialogContentText>
            <TextField
                autoFocus
                id="password"
                label="password"
                type="password"
                fullWidth
                variant="standard"
            />
            </DialogContent>
            <DialogActions>
            <Button onClick={togglePrompt}>Cancel</Button>
            <Button onClick={handleSubmit}>Download</Button>
            </DialogActions>
        </Dialog>
        <div className={styles.dashboard}>
            <Sidebar  />
            <div className={styles.adminListContainer}>
              <h1 id={styles.adminListHeading}>Download QR for individual NFT</h1>
              <Form className={styles.form} onSubmit={handleBrowserDefault}>
                    <FormGroup col>
                        <Label for="type" sm={2}>
                         NFT Type 
                        </Label>
                        <Col sm={10}>
                            <Input
                                required={true}
                                name="select"
                                type="select"
                                value={targetNFTType}
                                className={styles.text}
                                onChange={(e) => setTargetNFTType(e.target.value)}
                            >
                                {
                                    TargetNFTType.getResourceArray().map((item)=>(
                                        <option>
                                          {item.label}
                                       </option>
                                    ))
                                }
                            </Input>
                        </Col>
                    </FormGroup>
                    <FormGroup col>
                        <Label for="id" sm={2}>
                          Amount
                        </Label>
                        <Col sm={10}>
                            <Input
                                required={true}
                                id="amount"
                                name="Amount"
                                type="text"
                                min="0"
                                value={amount}
                                className={styles.number}
                                onChange={(e) => {
                                    const regex = /^[0-9\b]+$/; // regular expression to allow only digits
                                    const value = e.target.value
                                    if (value === '' || regex.test(value)) {
                                        setAmount(value)
                                    }}}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup check row>
                        <Col sm={{offset: 8, size: 2}}>
                            <Button variant='contained' onClick={togglePrompt}> Download QR </Button>
                        </Col>
                    </FormGroup>
                </Form>
              
            </div>
        </div>
    </Fragment>
  );
}
export default DownloadNFTQR;

