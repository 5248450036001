import React, { useState } from 'react';
import styles from "../../style/homePage.module.css"
// import img1 from "src/assets/images/carousel1.jpeg"
// import img2 from "src/assets/images/carousel2.webp"
// import img3 from "src/assets/images/carousel3.png"

import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
} from 'reactstrap';

const items = [
  {
    src: 'https://media1.thehungryjpeg.com/thumbs2/800_3745250_ljsnfg1vyanb9arrwj5sh1sdmdy0f336txubx0wg_casino-slot-machine-banner-vector-casino-game-lucky-slot-poster-illustration.jpg',
    altText: 'Pachinko machine',
    caption: 'Pachinko machine',
    key: 1,
  },
  {
    src: 'https://info.sapphirecapitalpartners.co.uk/hubfs/1_XOA5d5gWRU_zdgB-DdQ-ig%20%282%29-min.webp',
    altText: 'NFT',
    caption: 'NFT',
    key: 2,
  },
  {
    src: 'https://images.squarespace-cdn.com/content/v1/5f03cdaf7337416ff27ae9d1/1660567984916-22CYB9FGA377Y6ARPHJH/OG+PTW+Playmat+design.jpg?format=2500w',
    altText: 'Play to win NFTs',
    caption: 'Play to win NFTs',
    key: 3,
  },
];

function DisConHome(args) {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const slides = items.map((item) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.src}
      >
        <img src={item.src} alt={item.altText} className={styles.carouselImg} />
        <CarouselCaption
          captionText={item.caption}
          captionHeader={item.caption}
        />
      </CarouselItem>
    );
  });

  return (
    <Carousel
      activeIndex={activeIndex}
      next={next}
      previous={previous}
      fade={true}
      {...args}
    >
      <CarouselIndicators
        items={items}
        activeIndex={activeIndex}
        onClickHandler={goToIndex}
      />
      {slides}
      <CarouselControl
        direction="prev"
        directionText="Previous"
        onClickHandler={previous}
      />
      <CarouselControl
        direction="next"
        directionText="Next"
        onClickHandler={next}
      />
    </Carousel>
  );
}

export default DisConHome;