import React, {useState,useEffect} from 'react'
import styles from "../../style/homePage.module.css"
import {Container, Row, Col } from 'reactstrap';
import ImgSideClaimSection from '../../page-components/Claim/ImgSideClaimSection';
import { useParams,useNavigate } from "react-router-dom";
import { NFTController } from 'src/controllers/NFTControllers';
import Loader from '../../Layout/Loader/Loader';
import { toast } from 'react-toastify'

function ClaimNFTPage() {
  const Navigate = useNavigate();

  const [nft, setNFT] = useState(null);
  const [nftLoading, setNftLoading] = useState(true);

  const {id, securityKey} = useParams();

  const {isClaimed, findByIdAndSecurityKey} = NFTController()
  
 const claimData= {
    id,
    securityKey
  }
  useEffect(() => {
    
    if(id && securityKey){
      // isClaimed(id).then(async(result)=>{
      //   if(result){
      //     Navigate("/")
      //     toast.info("Already Claimed")
      //   }
      //    //  if error then sent to home page
      //   if(result===undefined){
      //     Navigate("/")
      //     // toast.info("Failed to get claim status ")
      //   }
      // });
      findByIdAndSecurityKey(id,securityKey).then(data => {
        if(data){
          setNftLoading(false)
          setNFT(data)
        }
         //  if error then sent to home page
        if(data===undefined){
          //toast.info("Failed to load the NFT Data")
          Navigate("/")
        }
       
      });
    }
  }, [id,securityKey])
  return (
    <>
      <Container className={styles.pageContainer}>
        {
          nftLoading ?
             <div>
               <Loader isLoading={true}/>
             </div>
          : 
          <div>
            <Row>
            <Col
              className={styles.recentText} 
              md={{
                offset: 1,
                size: 10
              }}
              sm="12"
            >
              Congrats you Won an NFT!
            </Col>
            </Row>
            <Row sm="1" md="2">
              <Col sm="11" md="5">
              <img src={nft.imageUri} alt={nft.tokenName} className={ styles.nft_img}/>
              </Col>
              <Col  sm="11" md="7">
                <ImgSideClaimSection nft = {nft} claimData= {claimData} />
              </Col>
            </Row>
          </div>

        }
          
        
      </Container>
    </>
  )
}

export default ClaimNFTPage
