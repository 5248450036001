import {
    LOGIN_REQUEST,
    LOGIN_FAIL,
    LOGIN_SUCCESS,
    REGISTER_USER_REQUEST,
    REGISTER_USER_SUCCESS,
    REGISTER_USER_FAIL,
    LOAD_USER_REQUEST,
    LOAD_USER_SUCCESS,
    LOAD_USER_FAIL,
    LOGOUT_SUCCESS,
    LOGOUT_FAIL,
    UPDATE_PROFILE_REQUEST,
    UPDATE_PROFILE_SUCCESS,
    UPDATE_PROFILE_FAIL,
    ALL_USERS_REQUEST,
    ALL_USERS_SUCCESS,
    ALL_USERS_FAIL,
    USER_FIND_BY_PUBLIC_ADDRESS_REQUEST,
    USER_FIND_BY_PUBLIC_ADDRESS_SUCCESS,
    USER_FIND_BY_PUBLIC_ADDRESS_FAIL,
    LOGIN_STATUS_SUCCESS,
    LOGIN_STATUS_FAIL,
    UPDATE_USER_REQUEST,
    UPDATE_USER_SUCCESS,
    UPDATE_USER_FAIL,
    CLEAR_ERRORS,
  } from "../../Constants/User/index";
import UserRepository from "src/domain/repositories/UserRepository"
import UserRepositoryImpl from "src/data/repositories/UserRepositoryImpl";
import TokenDatasource from "src/data/datasources/abstract/token_datasource";
import TokenDatasourceImpl from "src/data/datasources/local/token_datasource_impl";
import LocalStorageDatasourceImpl from "src/data/datasources/local/local_storage_datasource_impl";
import BaseError from "src/errors/BaseError";
import User from "@Domain/modals/User";
import {UserController} from "src/controllers/UserControllers"
  
  const userRepository :UserRepository = new UserRepositoryImpl();
  const tokenDatasourceImpl :TokenDatasource = new TokenDatasourceImpl(new LocalStorageDatasourceImpl());
  
  // Login
  // /**
  //  * @deprecated 
  //  */
//   export const login = (publicAddress:string,signature:string) => async (dispatch: (arg0: { type: string; payload?: unknown; }) =>Promise<void>) => {
//     try {
//       dispatch({ type: LOGIN_REQUEST });
//       tokenDatasourceImpl.clearToken()
//       const data = await userRepository.login(publicAddress,signature)
//       console.log(data)
//       tokenDatasourceImpl.setToken(data.token)
//       dispatch({ type: LOGIN_SUCCESS, payload: data });
//     } catch (error) {
//       if(error instanceof BaseError){
//         const err = error.getErrors();
//         dispatch({ type: LOGIN_FAIL, payload: err[0] });
//       }
//       dispatch({ type: LOGIN_FAIL, payload: error });
//     }
    

//   }
  
//   //Register
//   /**
//    * @deprecated
//    * @param user 
//    * @returns 
//    */
//   export const register = (user:User) => async (dispatch: (arg0: { type: string; payload?: any; }) => Promise<void>) => {
//     try {
//       dispatch({ type: REGISTER_USER_REQUEST });

//       const data = await userRepository.create(user)
  
//       dispatch({ type: REGISTER_USER_SUCCESS, payload: data });
//     } catch (error) {
//         if(error instanceof BaseError){
//           const err = error.getErrors();
//           dispatch({
//             type: REGISTER_USER_FAIL,
//             payload: err[0],
//           });
//         }
//         dispatch({
//           type: REGISTER_USER_FAIL,
//           payload: error,
//         });
//     }
     
//   };
  
//   // Load User
//   /**
//    * @deprecated
//    * @returns 
//    */
//   export const loadUser = () => async (dispatch: (arg0: { type: string; payload?: any; }) => void) => {
    
//     const {UserMe,UserSearch} = UserController()
    
//     dispatch({ type: LOAD_USER_REQUEST });
  
//     const result = await UserMe();

//     if(result.status){
//       dispatch({ type: LOAD_USER_SUCCESS, payload: result.user });
//     }else{
//       dispatch({type: LOAD_USER_FAIL,payload: result.error,
//       });
//     }

//   };


  
//   // Logout User
//   /**
//    * @deprecated
//    */
//   export const logout = () => async (dispatch: (arg0: { type: string; payload?: any; }) => Promise<void>) => {
//     try {
//       const status = await userRepository.logout()
//       tokenDatasourceImpl.clearToken()
//       dispatch({ type: LOGOUT_SUCCESS });
//     } catch (error) {
//       if(error instanceof BaseError){
//         const err = error.getErrors();
//         dispatch({
//           type: LOGOUT_FAIL,
//           payload: err[0],
//         });
//       }
//       dispatch({
//         type: LOGOUT_FAIL,
//         payload: error,
//       });
      
//       }
   
//   }
//  /**
//    * @deprecated
//    */
//   export const loginStatus = () => async (dispatch: (arg0: { type: string; payload?: any; }) => Promise<void>) => {
//     try {
//       const data = await userRepository.loginStatus()
//       tokenDatasourceImpl.clearToken()
//       dispatch({ type: LOGIN_STATUS_SUCCESS ,payload :data});
//     } catch (error) {
//       if(error instanceof BaseError){
//         const err = error.getErrors();
//         dispatch({
//           type: LOGIN_STATUS_FAIL,
//           payload: err[0],
//         });
//       }
//       dispatch({
//         type: LOGIN_STATUS_FAIL,
//         payload: error,
//       });
      
//       }
   
//   }
  
  
//   // Update Profile
//   /**
//    * @deprecated
//    */
//   export const updateProfile = (user: User) => async (dispatch: (arg0: { type: any; payload?: any; }) => void) => {
//     try {
//       dispatch({ type: UPDATE_PROFILE_REQUEST });
//       const data =  await userRepository.update(user)
  
//       dispatch({ type: UPDATE_PROFILE_SUCCESS, payload: data});
//     } catch (error) {
//       if(error instanceof BaseError){
//         const err = error.getErrors();
//         dispatch({
//           type: UPDATE_PROFILE_FAIL,
//           payload: err[0],
//         });
//       }
//       dispatch({
//         type: UPDATE_PROFILE_FAIL,
//         payload: error,
//       });
      
//     }
//   };
  
//   //todo
//   /**
//    * @deprecated
//    * @returns 
//    */
//   export const getAllUsers = () => async (dispatch: (arg0: { type: string; payload?: any; }) => void) => {
//     const {UserMe,UserSearch} = UserController()
//       dispatch({ type: ALL_USERS_REQUEST });
      
//        const result = await UserSearch({all:"all"})
//        if(result.status){
//         dispatch({ type: ALL_USERS_SUCCESS, payload: result.users });
//        }else{
//         dispatch({ type: ALL_USERS_FAIL, payload: result.error });
//        }
     
    
//   };
  
  
//   // Update User
//   /**
//    * @deprecated
//    */
//   export const updateUser = (user: User) => async (dispatch: (arg0: { type: string; payload?: any; }) => void) => {
//     try {
//       dispatch({ type: UPDATE_USER_REQUEST });
//       const data =  await userRepository.update(user)
  
//       dispatch({ type: UPDATE_USER_SUCCESS, payload: data});
//     } catch (error) {
//       if(error instanceof BaseError){
//         const err = error.getErrors();
//         dispatch({
//           type: UPDATE_USER_FAIL,
//           payload: err[0],
//         });
//       }
//       dispatch({
//         type: UPDATE_USER_FAIL,
//         payload: error,
//       });
      
//     }
   
//   };
  
  // Clearing Errors
  export const clearErrors = () => async (dispatch: (arg0: { type: string; }) => void) => {
    dispatch({ type: CLEAR_ERRORS });
  };
  