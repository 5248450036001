import Base from "./Base";

export class TargetNFTType extends Base {
    public static getResourceArray() {
        return [
            {
                id: 0,
                name: "R",
                label: "Regular-Bonus",
            },
            {
                id: 1,
                name: "B",
                label:"Big-Bonus",
            },
            {
                id: 2,
                name: "BB",
                label: "Big-Big-Bonus",
            },
            {
                id: 3,
                name: "RB",
                label:"Regular-Big-Bonus",
            },
            {
                id: 4,
                name: "BR",
                label: "Big-Regular-Bonus",
            },
            {
                id: 5,
                name: "RR",
                label:"Regular-Regular-Bonus",
            },
        ];
    }

    public static R(): TargetNFTType {
        return this.fromName<TargetNFTType>("R");
    }

    public static B(): TargetNFTType {
        return this.fromName<TargetNFTType>("B");
    }

    public static BB(): TargetNFTType {
        return this.fromName<TargetNFTType>("BB");
    }

    public static RB(): TargetNFTType {
        return this.fromName<TargetNFTType>("RB");
    }

    public static BR(): TargetNFTType {
        return this.fromName<TargetNFTType>("BR");
    }

    public static RR(): TargetNFTType {
        return this.fromName<TargetNFTType>("RR");
    }

    public isR(): boolean {
        return this.getName() === "R";
    }

    public isB(): boolean {
        return this.getName() === "B";
    }

    public isBB(): boolean {
        return this.getName() === "BB";
    }

    public isRB(): boolean {
        return this.getName() === "RB";
    }

    public isBR(): boolean {
        return this.getName() === "BR";
    }

    public isRR(): boolean {
        return this.getName() === "RR";
    }
}
