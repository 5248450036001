import styles from './nft_image_side_card.module.css'
import { Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input } from 'reactstrap';
import DescriptionCard from '../../Elements/DescriptionCard';
import WalletButton from '../../Elements/Buttons/WalletButton';
import { useState,useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { NFTController } from 'src/controllers/NFTControllers';
import Loader from '../../Layout/Loader/Loader';
import Chain from 'src/domain/value_objects/Chain';
import { clearErrors } from 'src/redux/Actions/User';
import { toast } from 'react-toastify'
import PleaseWaitModal from 'src/components/Layout/Loader/PleaseWait';
import { CLAIM_NFT_RESET } from 'src/redux/Constants/NFT';

function ImgSideClaimSection({nft, claimData}) {
  const dispatch =  useDispatch()
  const Navigate = useNavigate();

  const [modal, setModal] = useState(false);
  const [twitterId, setTwitterId] = useState(null);

  const {claim} = NFTController()

  const toggle = () => setModal(!modal);
  let {user,loading,error,  isAuthenticated} = useSelector(state => state.user)
  const {wallet,error:walletError} = useSelector(state => state.wallet)
  
  const {loading:claimLoading,success, error:nftClaimError }  = useSelector((state) => state.nftEditOrCreate)
    
  const handleClaim = async()=>{
    setModal(false);
    if(wallet.chainId){
      const chain = Chain.fromId(wallet.chainId)
      if(!chain || chain.isUnknown()){
        toast.warning("Invalid Network")
        return;
       }
      if(!isAuthenticated){
        toast.warn("Wallet not connected")
        return;
      }
      if(!user){
        toast.warn("Wait to load your information")
        return ;
      }
      
      await claim(claimData.id,claimData.securityKey,chain.getName(), user.publicAddress,twitterId)
    
    }else{
      toast.warn("chain couldn't detected")
      return;
    }
    
  }

  useEffect(() => {
    if(error){
      toast.error(error);
      dispatch(clearErrors());
    }
    if( walletError){
      alert(walletError);
      dispatch(clearErrors());
    }
    if (nftClaimError) {
      toast.error(nftClaimError);
      dispatch(clearErrors());
  }
  if (success) {
      toast.success("NFT claimed Successfully");
      dispatch(clearErrors());
      dispatch({ type: CLAIM_NFT_RESET });
      Navigate("/")
  }
   
  }, [ dispatch, error, walletError,nftClaimError,success])

  return (
    <div className={styles.basicDetails}>
    <div className={styles.basicHeadings}>
    <Row>
      <Col className={styles.nftName}>
        {nft.tokenName}
      </Col>
    </Row >
    <Row>
      <Col className={styles.owner}>
        <span>
            Contract Address : {String(nft.contractAddress).substring(0,5) + "....." + String(nft.contractAddress).substring(String(nft.contractAddress).length - 6, String(nft.contractAddress).length - 1)}
        </span>
      </Col>
    </Row>
    </div>
    
    <div className={styles.walletConnectSection}>

    { 
      loading ?
       <Loader isLoading={true}/>
      :isAuthenticated ? 
        <Row>
          <Col>
            <Button disabled={nft? false :true} onClick={user ? user.twitterId ? handleClaim : toggle : ()=>{}} className={styles.claimButton}>
                Claim NFT!
            </Button>
            <Modal isOpen={modal} toggle={toggle} centered={true}>
              <ModalHeader toggle={toggle}>Details of Winner</ModalHeader>
               <ModalBody>
                  <Form>
                    <FormGroup>
                      <Label for="email">
                          Twitter Id(Optional)
                      </Label>
                      <Input 
                      id='twitterId'
                      name='TwitterId'
                      placeholder='@t2web_cojp'
                      type='string'
                      value={twitterId}
                      onChange={(e)=> setTwitterId(e.target.value)}
                      >
                      </Input>
                    </FormGroup>
                  </Form>
                </ModalBody>
                <ModalFooter>
                  <Button disabled={nft? false :true} color="success" onClick={handleClaim}>
                      Claim
                  </Button>
                  <Button color="secondary" onClick={(e)=>{toggle(); setTwitterId(null)}}>
                      Cancel
                  </Button>
                </ModalFooter>
            </Modal>
          </Col>
        </Row> 
      :
        <div>
            <Row xs={1}>
                <Col>
                    <WalletButton></WalletButton>
                </Col>
                <br></br>
                <br></br>
                <Col>
                Connect wallet to Claim NFT!
                </Col>
            </Row>
        </div>
    }
    </div>
    <Row>
      <Col >
        <DescriptionCard nft ={nft}></DescriptionCard>
      </Col>
    </Row>
    <PleaseWaitModal showModal={claimLoading? true:false}/>
    </div>
  );
}

export default ImgSideClaimSection;