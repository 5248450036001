import './App.css';
import "font-awesome/css/font-awesome.css";
import "bootstrap-social/bootstrap-social.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.css";
import Header from "./components/Layout/Header/Header"
import Footer from "./components/Layout/Footer/Footer"
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import HomePage from './components/Pages/Home/HomePage';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { blueGrey } from '@mui/material/colors';
import ClaimNFTPage from './components/Pages/Claim/ClaimNFTPage';
import NFTs from './components/Pages/Admin/NFTsList';
import Users from './components/Pages/Admin/UsersList';
import MintNft from './components/Pages/Admin/MintNft';
import DownloadNFTQR from './components/Pages/Admin/DownloadNFTQR';
import DownloadNFTLink from './components/Pages/Admin/DownloadNFTLink';
import './App.css';
import MintMoreNFTs from './components/Pages/Admin/MintMoreNFTs';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useMetamask } from 'src/Service/web3';
import {UserController} from 'src/controllers/UserControllers';
import { LOGIN_SUCCESS } from './redux/Constants/User';
import { clearErrors } from './redux/Actions/User';
import ProtectedRoute from './components/Route/ProtectedRoute';
import { toast } from 'react-toastify'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const theme = createTheme({
  palette: {
    primary: {main : blueGrey[900]},
  },
});

function App() {
  const dispatch =  useDispatch()
    
  const {checkMetamaskAvailability,initializeProvider,setWalletAddress} = useMetamask()
  const {user, error, isAuthenticated}  = useSelector((state) => state.user)
  const {wallet}  = useSelector((state) => state.wallet)
  const {UserLoginStatus, UserMe} = UserController()

  useEffect(() => {
    checkMetamaskAvailability()
    // initializeProvider();
  }, [])


  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch(clearErrors());
    }
    if(isAuthenticated){
      UserMe()
      initializeProvider();
      
      setWalletAddress();
    }else{
      UserLoginStatus().then((result)=>{
        if(result){
          if(result.isLoggedIn===true&& wallet.isConnected){
            if(!isAuthenticated){
              dispatch({type:LOGIN_SUCCESS})
              UserMe()
              initializeProvider();
              
              setWalletAddress();
            }
          }
        }else{
  
        }
      })
    }
    
  }, [dispatch,error,  isAuthenticated, wallet.isConnected])
  
  return (
    <div className="App">
      <ThemeProvider theme={theme}> 
      <ToastContainer
       position="top-right"
       autoClose={5000}
       hideProgressBar={false}
       newestOnTop={false}
       closeOnClick
       rtl={false}
       pauseOnFocusLoss
       draggable
       pauseOnHover
       //theme="grey"
      />
      <BrowserRouter>
      <Header ></Header>
        <Routes>
          <Route exact path="/" element={<HomePage/>} />
          <Route  exact path="/user/nft/claim/:id/:securityKey" element={<ClaimNFTPage/>} />

          {/* Admin routes */}
          <Route exact isAdmin={true} path="/admin/nfts" 
            element={
              <ProtectedRoute isAdmin={true}>
                <NFTs/>
              </ProtectedRoute>
            } 
          />
          <Route exact isAdmin={true} path="/admin/users" 
            element={
              <ProtectedRoute isAdmin={true}>
                <Users/>
              </ProtectedRoute>
            } 
          />
          <Route exact isAdmin={true} path="/admin/nft/mint/new" 
            element={
              <ProtectedRoute isAdmin={true}>
                <MintNft/>
              </ProtectedRoute>
            } 
          />
          <Route exact isAdmin={true} path="/admin/nft/mint/additionSupply" 
            element={
              <ProtectedRoute isAdmin={true}>
                <MintMoreNFTs/>
              </ProtectedRoute>
            } 
          />
          <Route exact isAdmin={true} path="/admin/nft/mint/burn" 
            element={
              <ProtectedRoute isAdmin={true}>
                <MintMoreNFTs/>
              </ProtectedRoute>
            } 
          />
          <Route exact isAdmin={true} path="/admin/nft/download/qrs" 
            element={
              <ProtectedRoute isAdmin={true}>
                <DownloadNFTQR/>
              </ProtectedRoute>
            } 
          />
          <Route exact isAdmin={true} path="/admin/nft/download/links" 
            element={
              <ProtectedRoute isAdmin={true}>
                <DownloadNFTLink/>
              </ProtectedRoute>
            } 
          />
          <Route  path="*" 
            element={
              <Navigate to="/"/>
            } 
          />
        </Routes>
        </BrowserRouter>
      <Footer></Footer>
      </ThemeProvider>
    </div>
  );
}

export default App;
