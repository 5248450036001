import {
    LOGIN_REQUEST,
    LOGIN_FAIL,
    LOGIN_SUCCESS,
    REGISTER_USER_REQUEST,
    REGISTER_USER_SUCCESS,
    REGISTER_USER_FAIL,
    LOAD_USER_REQUEST,
    LOAD_USER_SUCCESS,
    LOAD_USER_FAIL,
    LOGOUT_SUCCESS,
    LOGOUT_FAIL,
    UPDATE_PROFILE_REQUEST,
    UPDATE_PROFILE_SUCCESS,
    UPDATE_PROFILE_FAIL,
    ALL_USERS_REQUEST,
    ALL_USERS_SUCCESS,
    ALL_USERS_FAIL,
    UPDATE_USER_REQUEST,
    UPDATE_USER_SUCCESS,
    UPDATE_USER_FAIL,
    CLEAR_ERRORS,
    UPDATE_PROFILE_RESET,
    UPDATE_USER_RESET,
    USER_DETAILS_REQUEST,
    USER_DETAILS_SUCCESS,
    USER_DETAILS_FAIL,
    LOGIN_STATUS_REQUEST,
    LOGIN_STATUS_SUCCESS,
    LOGIN_STATUS_FAIL,
    USER_SEARCH_REQUEST,
    USER_SEARCH_SUCCESS,
    USER_SEARCH_FAIL,
    
  } from "../../Constants/User/index";
  
  export const userReducer = (state = { user: {}}, action: { type: any; payload: any; }):any => {
    switch (action.type) {
      case LOGIN_REQUEST:
      case REGISTER_USER_REQUEST:
      
        return {
          loading: true,
          isAuthenticated: false,
        };
      case LOAD_USER_REQUEST:
        return {
          ...state,
          loading: true,
        };
      // case LOGIN_STATUS_SUCCESS:
      //   return {
      //     tokenValidity :action.payload.isLoggedIn,
      //   };
      case LOGIN_SUCCESS:
        return {
          loading: true,
          isAuthenticated: true,
          // user: {
          //   ...state.user,
          //   token:action.payload,
          // }
        };
      case REGISTER_USER_SUCCESS:
        return {
          loading: false,
          isAuthenticated: false,
        };
      case LOAD_USER_SUCCESS:
        return {
          ...state,
          loading: false,
          isAuthenticated:true,
          user: action.payload,
        };
  
      case LOGOUT_SUCCESS:
        return {
          loading: false,
          user: null,
          isAuthenticated: false
        };
      case LOGIN_FAIL:
      case REGISTER_USER_FAIL:
        return {
          loading: false,
          isAuthenticated: false,
          user: null,
          error: action.payload,
        };
  
      case LOAD_USER_FAIL:
        return {
          loading: false,
          user: null,
          error: action.payload,
        };
  
      case LOGOUT_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      // case LOGIN_STATUS_FAIL:
      // return {
      //   tokenValidity :action.payload.isLoggedIn,
      // };
      case CLEAR_ERRORS:
        return {
          ...state,
          error: null,
        };
  
      default:
        return state;
    }
  };


  export const profileReducer = (state = {}, action: { type: any; payload: { success: any; message: any; }; }) => {
    switch (action.type) {
      case UPDATE_PROFILE_REQUEST:
      case UPDATE_USER_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case UPDATE_PROFILE_SUCCESS:
      case UPDATE_USER_SUCCESS:
        return {
          ...state,
          loading: false,
          isUpdated: true
        };
      case UPDATE_PROFILE_FAIL:
      case UPDATE_USER_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
  
      case UPDATE_PROFILE_RESET:
      case UPDATE_USER_RESET:
        return {
          ...state,
          isUpdated: false,

        };
      case CLEAR_ERRORS:
        return {
          ...state,
          error: null,
        };
  
      default:
        return state;
    }
  };
  
  
  export const allUsersReducer = (state = { users: [] }, action: { type: any; payload: any; }) => {
    switch (action.type) {
      case ALL_USERS_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case ALL_USERS_SUCCESS:
        return {
          ...state,
          loading: false,
          users: action.payload,
        };
  
      case ALL_USERS_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
  
      case CLEAR_ERRORS:
        return {
          ...state,
          error: null,
        };
  
      default:
        return state;
    }
  };

  export const userDetailsReducer = (state = { user: {} }, action: { type: any; payload: any; }) => {
    switch (action.type) {
      case USER_DETAILS_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case USER_DETAILS_SUCCESS:
        return {
          ...state,
          loading: false,
          user: action.payload,
        };
  
      case USER_DETAILS_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
  
      case CLEAR_ERRORS:
        return {
          ...state,
          error: null,
        };
  
      default:
        return state;
    }
  };

  export const userLoggInStatusReducer = (state = { }, action: { type: any; payload: any; }) => {
    switch (action.type) {
      case LOGIN_STATUS_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case LOGIN_STATUS_SUCCESS:
        return {
          ...state,
          loading: false,
        };
  
      case LOGIN_STATUS_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
  
      case CLEAR_ERRORS:
        return {
          ...state,
          error: null,
        };
  
      default:
        return state;
    }
  };

  export const userSearchReducer = (state = { user:{}}, action: { type: any; payload: any; }) => {
    switch (action.type) {
      case USER_SEARCH_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case USER_SEARCH_SUCCESS:
        return {
          ...state,
          loading: false,
          user:action.payload
        };
  
      case USER_SEARCH_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
  
      case CLEAR_ERRORS:
        return {
          ...state,
          error: null,
        };
  
      default:
        return state;
    }
  };


  
  