import { DateTime } from 'luxon'
import { BaseModel } from './BaseModel'
import { NFTJson } from '../../data/jsons/NFTJson'
import { NFTUpdateJson } from '../../data/jsons/nft/NFTUpdateJson'
import { ValueObjectType } from '../../data/jsons/ValueObjectType'
import { NFTCreateJson } from '../../data/jsons/nft/NFTCreateJson'
import { TargetNFTType } from 'src/domain/value_objects/TargetNftType'

export default class NFT implements BaseModel {

public static fromJson(json:NFTJson): NFT {
    if (json.nftType.id === undefined) {
        throw new Error(`nftType id is undefined`)
    }
    const nftType = TargetNFTType.fromId<TargetNFTType>(json.nftType.id)
    if (nftType.isUnknown()) {
        throw new Error(`LanguageId is invalid: ${json.nftType.id}`)
    }
    return new this(
    json.id,
    json.contractAddress,
    json.tokenUri,
    json.imageUri,
    json.tokenName,
    json.description,
    json.isClaimed,
    json.nftType,
    json.minter,
    json.owner,
    DateTime.fromISO(json.createdAt!),
    DateTime.fromISO(json.updatedAt!)
    )
}

constructor(
    public id: string, 
    public contractAddress: string,
    public tokenUri: string | null,
    public imageUri: string |null,
    public tokenName: string ,
    public description: string | null,
    public isClaimed: boolean,
    public nftType: ValueObjectType ,
    public minter: string,
    public owner: string | null,
    public createdAt: DateTime | null,
    public updatedAt: DateTime | null
){}

public toJson(): NFTJson {
    return {
    id: this.id,
    contractAddress: this.contractAddress,
    tokenUri: this.tokenUri,
    imageUri: this.imageUri,
    tokenName: this.tokenName,
    description: this.description,
    isClaimed: this.isClaimed,
    nftType: this.nftType,
    minter: this.minter,
    owner: this.owner,
    createdAt: this.createdAt!.toISO(),
    updatedAt: this.updatedAt!.toISO()
    }
}

public toUpdateJson(): NFTUpdateJson {
    if (this.id === undefined) {
    throw new Error('id must be specified to update')
    }

    return {
        id: this.id,
        owner: this.owner,
        isClaimed: this.isClaimed
    }
}

public toCreateJson(amount: number, chain: string, image:File): NFTCreateJson {
    // if (this.id === undefined) {
    // throw new Error('id must be specified to update')
    // }

    return {
        name:this.tokenName,
        description: this.description,
        chain: chain,
        targetNFTType: this.nftType!.name,
        amount: amount,
        image: image
    }
}
}
