import React from "react";
import Backdrop from '@mui/material/Backdrop';
import { CircularProgress } from "@mui/material";

const Loader = ({isLoading}) => {
  return (
    <div >
      <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={isLoading}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
    </div>
  );
};

export default Loader;
