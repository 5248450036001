import * as Papa from 'papaparse';
import CryptoJS from 'crypto-js';

// make a request to download the encrypted CSV file
export default function decryptData(result: any, password: string){
  try {
    const decryptedCsv = CryptoJS.AES.decrypt(result, password).toString(CryptoJS.enc.Utf8);
    // parse the decrypted CSV string into an array of arrays
    const data = Papa.parse(decryptedCsv).data as any;
    const csvData: any[] = [];
    for(let i = 0;i < data.length; ++i) {
      csvData.push({index : i, url: data[i][0]})
    }
    return csvData;
  }catch(e) {
    throw (e)
}
}