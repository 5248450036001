import BaseError from "./BaseError";

export class GeneralError extends BaseError {}

// 400
export class BadRequestError extends BaseError {}

// 401
export class UnauthorizedError extends BaseError {}

// 403
export class ForbiddenError extends BaseError {}

// 404
export class NotFoundError extends BaseError {}

// 405
export class MethodNotAllowedError extends BaseError {}

// 408
export class RequestTimeoutError extends BaseError {}

// 429
export class TooManyRequestsError extends BaseError {}

// 500
export class InternalServerError extends BaseError {}

// 501
export class NotImplementedError extends BaseError {}

// 502
export class BadGatewayError extends BaseError {}

// 503
export class ServiceUnavailableError extends BaseError {}

// 504
export class GatewayTimeoutError extends BaseError {}
