import { NFTJson } from "../../jsons/NFTJson";
import NFTDatasource from "../abstract/NFTDatasource";
import ApiService from "./ApiService";
import { Query } from "../abstract/nftDatasource/query";
import { NFTUpdateJson } from "../../jsons/nft/NFTUpdateJson";
import { NFTCreateJson } from "../../jsons/nft/NFTCreateJson";
import TokenDatasourceImpl from "../local/token_datasource_impl";
import LocalStorageDatasourceImpl from "../local/local_storage_datasource_impl";

export default class NFTDatasourceImpl implements NFTDatasource {

    private apiService : ApiService;

    constructor () {
        this.apiService = new ApiService(new TokenDatasourceImpl(new LocalStorageDatasourceImpl()));
    }

    findById(id: string): Promise<NFTJson> {
        return new Promise((resolve, reject) => {
            this.apiService.get(`/nft/show/detail/${id}`).then(data => {
                resolve(data.nft as NFTJson)
            }).catch (error => {
                reject(error);
            })
        })
    }

    findByIdAndSecurityKey(id:string,securityKey: string): Promise<NFTJson> {
        return new Promise((resolve, reject) => {
            this.apiService.get(`/nft/get/detail`,{id,securityKey}).then(data => {
                resolve(data.nft as NFTJson)
            }).catch (error => {
                reject(error);
            })
        })
    }

    findByOwner(ownerAddress: string): Promise<NFTJson[]> {
        return new Promise((resolve, reject) => {
            this.apiService.get(`/nfts/findByOwnerAddress`,{ownerAddress}).then(data => {
                resolve(data.nfts as NFTJson[])
            }).catch (error => {
                reject(error);
            })
        })
    }

    findByMinter(minterAddress: string): Promise<NFTJson[]> {
        return new Promise((resolve, reject) => {
            this.apiService.get(`/nfts/findByMinterAddress`,{minterAddress}).then(data => {
                resolve(data.nfts as NFTJson[])
            }).catch (error => {
                reject(error);
            })
        })
    }

    update(params: NFTUpdateJson): Promise<NFTJson> {
        return new Promise((resolve, reject) => {
            this.apiService.post(
                '/nft/update',
                params
            )
            .then(data => {
                resolve(data.nft as NFTJson)
            })
            .catch(e => {
                reject(e)
            })
        })
    }
// ignore
    existsById(id: string) : Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.apiService.get(`nft/existsById/${id}`).then(data => {
                resolve(data.result as boolean)
            }).catch(e => {
                reject(e);
            })
        })
    }
    
    isClaimed(id: string) : Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.apiService.get(`/nft/claim/status`,{id}).then(data => {
                resolve(data.result as boolean)
            }).catch(e => {
                reject(e);
            })
        })
    }
//ignore
    search(query: Query): Promise<NFTJson[]> {
        return new Promise((resolve, reject) => {
            this.apiService.get('nfts/search', query.toJson())
            .then(data => {
                resolve(data.nfts as NFTJson[])
            }).catch(error => {
                reject(error);
            })
        })
    }

    findAll() : Promise<NFTJson[]> {
        return new Promise((resolve, reject) => {
            this.apiService.get('nfts/show')
            .then(data => {
                resolve(data.nfts as NFTJson[])
            }).catch(error => {
                reject(error);
            })
        })
    }

 //ignore
    totalCount(query: Query): Promise<number> {
        const params = query.toJson()
        delete params.offset
        delete params.limit
        delete params.sortBy

        return new Promise((resolve, reject) => {
            this.apiService.get('/nft/totalCount', params)
            .then(data => {
                resolve(data.totalCount as number)
            })
            .catch(e => {
                reject(e)
            })
        })
    }

    create(params: NFTCreateJson): Promise<boolean> {
        
        return new Promise((resolve, reject) => {
            this.apiService.post(
                '/nft/create',
                params,
                true
            )
            .then(data => {
                resolve(data.result as boolean)
            })
            .catch(e => {
                reject(e)
            })
        })
    }

    claim(id: string, securityKey: string, chain: string, publicAddess: string, twitterId?:string): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.apiService.post(
                `/nft/claim`,
                {chain, publicAddess, id,securityKey,twitterId}
            )
            .then(data => {
                resolve(data.result as boolean)
            })
            .catch(e => {
                reject(e)
            })
        })
    }

    mintAdditionalSupply(chain: string, targetNFTType: string, amount: number): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.apiService.post(
                '/nft/mintAdditionalSupply',
                {chain, targetNFTType, amount},
                false
            )
            .then(data => {
                resolve(data.result as boolean)
            })
            .catch(e => {
                reject(e)
            })
        })
    }
    //ignore
    burn(chain: string, id: string, amount: number): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.apiService.post(
                '/users/burn',
                {chain: chain, id: id, amount: amount}
            )
            .then(data => {
                resolve(data.result as boolean)
            })
            .catch(e => {
                reject(e)
            })
        })
    }

    getAndDecryptUrls(chain: string, targetNFTType: string, amount: number): Promise<any> {
        return new Promise((resolve, reject) => {
            this.apiService.get(
                '/nft/createAndEncryptUrls',
                {chain, targetNFTType, amount}
            )
            .then(data => {
                resolve(data)
            })
            .catch(e => {
                reject(e)
            })
        })
    }
}