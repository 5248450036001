declare global {
  interface Navigator {
      msSaveBlob?: (blob: any, defaultName?: string) => boolean
  }
}

// make a request to download the encrypted CSV file
export default function DownloadUrls(csvData: any[] | undefined, filename: string) {

    const processedCsvData = csvmaker(csvData);
    const csvBlob = new Blob(processedCsvData, { type: "text/csv;charset=utf-8;" });
    try {
    if (navigator.msSaveBlob) {
        navigator.msSaveBlob(csvBlob, filename);
    } else {
        const link = document.createElement("a");
        if (link.download !== undefined) {
        const url = URL.createObjectURL(csvBlob);
        link.setAttribute("href", url);
        link.setAttribute("download", filename);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        }
    }
    } catch(e) {
    // console.log(e);
    }
}

const csvmaker = (data: any) => {
    let cleanDataCsv: any[] = [];
    data.map((cleanData: any, index: number) => {
        let csvRows = [];

        if(index === 0) {
        // Headers
        const headers = Object.keys(cleanData);
        csvRows.push(headers.join(','));
        }

        let values = Object.values(cleanData).join(',');
        csvRows.push(values)

        // Returning the array joining with new line
        cleanDataCsv.push(csvRows.join('\n') , '\n') 
    })
    return cleanDataCsv;
}