import { UserJson } from "../../jsons/UserJson";
import UserDatasource from "../abstract/UserDatasource";
import ApiService from "./ApiService";
import { Query } from "../abstract/userDatasource/query";
import { UserUpdateJson } from "../../jsons/user/UserUpdateJson";
import { LoginStatusJson } from "../../jsons/LoginStatusJson";
import { LoginSuccessJson } from "../../jsons/LoginSuccessJson";
import { UserCreateJson } from "../../jsons/user/UserCreateJson";
import TokenDatasourceImpl from "../local/token_datasource_impl";
import LocalStorageDatasourceImpl from "../local/local_storage_datasource_impl";

export default class UserDatasourceImpl implements UserDatasource {

    private apiService : ApiService;

    constructor () {
        this.apiService = new ApiService(new TokenDatasourceImpl(new LocalStorageDatasourceImpl()));
    }

    findById(id: string): Promise<UserJson> {
        return new Promise((resolve, reject) => {
            this.apiService.get(`/user/show/details/${id}`).then(data => {
                resolve(data.user as UserJson)
            }).catch (error => {
                reject(error);
            })
        })
    }

    findByPublicAddress(publicAddress: string): Promise<UserJson> {
        return new Promise((resolve, reject) => {
            this.apiService.get(`/user/findByPublicAddress`,{publicAddress}).then(data => {
                resolve(data.user as UserJson)
            }).catch (error => {
                reject(error);
            })
        })
    }

    findByTwitterId(twitterId: string): Promise<UserJson> {
        return new Promise((resolve, reject) => {
            this.apiService.get(`/user/findByTwitterId`, {twitterId: twitterId}).then(data => {
                resolve(data.user as UserJson)
            }).catch (error => {
                reject(error);
            })
        })
    }

    update(params: UserUpdateJson): Promise<UserJson> {
        return new Promise((resolve, reject) => {
            this.apiService.post(
                '/user/update',
                params
            )
            .then(data => {
                resolve(data.user as UserJson)
            })
            .catch(e => {
                reject(e)
            })
        })
    }

    existsByPublicAddress(publicAddress: string) : Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.apiService.get(`/user/exists/publicAddress/status`,{publicAddress}).then(data => {
                resolve(data.status as boolean)
            }).catch(e => {
                reject(e);
            })
        })
    }

    search(query: Query): Promise<UserJson[]> {
        return new Promise((resolve, reject) => {
            this.apiService.get('user/search', query.toJson())
            .then(data => {
                resolve(data.user as UserJson[])
            }).catch(error => {
                reject(error);
            })
        })
    }

    findAll(): Promise<UserJson[]> {
        return new Promise((resolve, reject) => {
            this.apiService.get('users/show')
            .then(data => {
                resolve(data.user as UserJson[])
            }).catch(error => {
                reject(error);
            })
        })
    }

    // not done  -- ignore
    totalCount(query: Query): Promise<number> {
        const params = query.toJson()
        delete params.offset
        delete params.limit
        delete params.sortBy

        return new Promise((resolve, reject) => {
            this.apiService.get('/user/totalCount', params)
            .then(data => {
                resolve(data.totalCount as number)
            })
            .catch(e => {
                reject(e)
            })
        })
    }

    loginStatus(): Promise<LoginStatusJson> {
        return new Promise((resolve, reject) => {
            this.apiService.get('/user/loginStatus')
            .then(data => {
                resolve(data as LoginStatusJson)
            })
            .catch(e => {
                reject(e)
            })
        })
    }
    IsCurrentPublicAddressLoggedIn(publicAddress:string): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.apiService.get('/user/IsCurrentPublicAddressLoggedIn',{publicAddress})
            .then(data => {
                resolve(data.result as boolean)
            })
            .catch(e => {
                reject(e)
            })
        })
    }
    
    nonce(publicAddress: string): Promise<number> {
        return new Promise((resolve, reject) => {
            this.apiService.get(
                '/user/nonce',
                { publicAddress: publicAddress }
            )
            .then(data => {
                resolve(data.nonce as number)
            })
            .catch(e => {
                reject(e)
            })
        })
    }

    me(): Promise<UserJson> {
        return new Promise((resolve, reject) => {
            this.apiService.get(
                '/user/me'
            )
            .then(data => {
                resolve(data.user as UserJson)
            })
            .catch(e => {
                reject(e)
            })
        })
    }

    login(publicAddress: string, signature: string): Promise<LoginSuccessJson> {
        return new Promise((resolve, reject) => {
            this.apiService.post(
                '/user/login',
                {
                    publicAddress: publicAddress,
                    signature: signature
                }
            )
            .then(data => {
                resolve(data as LoginSuccessJson)
            })
            .catch(e => {
                reject(e)
            })
        })
    }

    logout(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.apiService.post(
                '/user/logout'
            )
            .then(data => {
                resolve(data.result as boolean)
            })
            .catch(e => {
                reject(e)
            })
        })
    }

    create(params: UserCreateJson,signature:string): Promise<UserJson> {
        
        let {...param } = params as any
        param["signature"]= signature;
        return new Promise((resolve, reject) => {
            this.apiService.post(
                '/user/create',
                param
            )
            .then(data => {
                resolve(data.user as UserJson)
            })
            .catch(e => {
                reject(e)
            })
        })
    }
}