import axios, { Axios, AxiosError, AxiosResponse } from 'axios'
import { ErrorsJson } from '../../jsons/ErrorsJson';
import BaseError from "../../../errors/BaseError";
import type TokenDatasource from '../abstract/token_datasource'
import { BadGatewayError, BadRequestError, ForbiddenError, GatewayTimeoutError, GeneralError, InternalServerError, MethodNotAllowedError, NotFoundError, NotImplementedError, RequestTimeoutError, ServiceUnavailableError, TooManyRequestsError, UnauthorizedError } from "../../../errors/CustomDatasourceErrors";

export default class ApiService {

    static defaultBaseUrl = 'https://api-goxslot.com'
    static defaultTimeout = 35000000000
    static defaultResponseType = 'json'
    static defaultResponseEncoding = 'utf8'
    static defaultHeaders = {
        'Content-Type': 'application/json;charset=utf-8;multipart/form-data',
        'Access-Control-Allow-Origin': '*'
    }

    static formUrlEncodedContentType = { 'Content-type': 'application/x-www-form-urlencoded' }

    static authorizationBrearer(token: string): string {
        return `Bearer ${token}`
    }
    private _tokenDatasource: TokenDatasource
    private _axios: Axios

    constructor(
        tokenDatasource: TokenDatasource,
        baseURL: string = ApiService.defaultBaseUrl,
        timeout: number = ApiService.defaultTimeout,
        headers?: { [key: string]: string }
    ) {
        this._tokenDatasource = tokenDatasource
        let config: { [key: string]: any } = {
            baseURL: baseURL,
            timeout: timeout
        }

        config.headers = (headers !== undefined)
            ? { ...ApiService.defaultHeaders, ...headers }
            : ApiService.defaultHeaders

        this._axios = axios.create(config)

        this._registerRequestInterceptor()
        this._registerResponseInterceptor()
    }

    public setDefaultBaseUrl(baseUrl: string) {
        this._axios.defaults.baseURL = baseUrl
    }

    public setDefaultTimeout(timeout: number) {
        this._axios.defaults.timeout = timeout
    }

    public setDefaultHeaders(headers: { [key: string]: string }) {
        this._axios.defaults.headers.common = headers
    }

    public setDefaultHeader(key: string, value: string) {
        this._axios.defaults.headers.common[key] = value
    }

    public get(url: string, params: { [key: string]: any } | null = null, _?: { [key: string]: any }): Promise<{ [key: string]: any }> {
        let config: { [key: string]: any } = {}
        if (params !== null) {
            config.params = params
        }

        return new Promise((resolve, reject) => {
            this._axios.get(url, config)
                .then(response => {
                    resolve(response.data)
                })
                .catch(e => {
                    reject(e)
                })
        })
    }

    public post(url: string, params: { [key: string]: any } | null = null, multimedia?:boolean, _?: { [key: string]: any }): Promise<{ [key: string]: any }> {
        let data: { [key: string]: any } = {}
        if (params !== null) {
            data = params
        }
        
        if(multimedia){
            let dataKey = Object.keys(data)
            let myform = new FormData();
            const headers = { 'content-type': 'multipart/form-data' }
            for(let i=0; i<dataKey.length; i++){
                myform.append(dataKey[i], data[dataKey[i]]);
            }
           
            
            return new Promise((resolve, reject) => {
                this._axios.post(url, myform,{ headers })
                    .then(response => {
                        resolve(response.data)
                    })
                    .catch(e => {
                        reject(e)
                    })
            })

        }else{
            return new Promise((resolve, reject) => {
                this._axios.post(url, data)
                    .then(response => {
                        resolve(response.data)
                    })
                    .catch(e => {
                        reject(e)
                    })
            })
        }
        
    }

    private _registerRequestInterceptor() {
        this._axios.interceptors.request.use((config) => {
            const token = this._tokenDatasource.getToken()
            if (token !== null) {
                config!.headers!.Authorization = ApiService.authorizationBrearer(token)
            }
            return config
        })
    }

    private _registerResponseInterceptor() {
        this._axios.interceptors.response.use(
            (response: AxiosResponse) => {
                return response;
            },
            (error: AxiosError) => {

                if (error.response === undefined || error.response!.data === undefined) {
                    const generalError: BaseError = new GeneralError(error.message, [error.message])
                    return Promise.reject(generalError)
                }

                let errorMessages: string[] = []

                const data: any = error.response!.data!
                if (data.message !== undefined) {
                    errorMessages.push(data.message)
                } else if (data.errors !== undefined) {
                    const errorResponse: ErrorsJson = data as ErrorsJson
                    errorMessages = errorResponse.errors.map((error) => { return error.message })
                }

                let customError: BaseError | null = null;
                switch (error.response.status) {
                    case 400:
                        customError = new BadRequestError(error.message, errorMessages)
                        break
                    case 401:
                        customError = new UnauthorizedError(error.message, errorMessages)
                        break
                    case 403:
                        customError = new ForbiddenError(error.message, errorMessages)
                        break
                    case 404:
                        customError = new NotFoundError(error.message, errorMessages)
                        break
                    case 405:
                        customError = new MethodNotAllowedError(error.message, errorMessages)
                        break
                    case 408:
                        customError = new RequestTimeoutError(error.message, errorMessages)
                        break
                    case 429:
                        customError = new TooManyRequestsError(error.message, errorMessages)
                        break
                    case 500:
                        customError = new InternalServerError(error.message, errorMessages)
                        break
                    case 501:
                        customError = new NotImplementedError(error.message, errorMessages)
                        break
                    case 502:
                        customError = new BadGatewayError(error.message, errorMessages)
                        break
                    case 503:
                        customError = new ServiceUnavailableError(error.message, errorMessages)
                        break
                    case 504:
                        customError = new GatewayTimeoutError(error.message, errorMessages)
                        break
                    default:
                        customError = new GeneralError(error.message, errorMessages)
                }
                return Promise.reject(customError)
            }
        );
    }
}
