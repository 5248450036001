import React,{useEffect} from 'react'
import styles from "../../style/homePage.module.css"
import {Container, Row, Col } from 'reactstrap';
import NFTImagesideCard from '../../page-components/Home/NFTImagesideCard';
import NFTDetailsCard from '../../page-components/Home/NFTDetailsCard';
import AlreadyClaimedNFTs from '../../page-components/Home/AlreadyClaimedNFTs';
import { useDispatch, useSelector } from "react-redux";
import { clearErrors } from 'src/redux/Actions/User';
import DisConHome from '../../page-components/Home/DisConHome';
import Loader from '../../Layout/Loader/Loader';
import { toast } from 'react-toastify'

function HomePage() {
  const dispatch =  useDispatch()
  const {user,loading,error, isAuthenticated} = useSelector(state => state.user)

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch(clearErrors());
    }
   }, [dispatch,error])

  
  return (
    <>
      <Container className={styles.pageContainer}>
        { !isAuthenticated ?
            <div>
              <Row>
                <Col
                  className={styles.recentText} 
                  md={{
                    offset: 1,
                    size: 10
                  }}
                  sm="12"
                >
                  Connect Wallet to win NFTs!
                </Col>
              </Row>
             <DisConHome></DisConHome>
            </div>
          : [
            loading ?
            // <div>
            //  <Row>
            //     <Col
            //       className={styles.recentText} 
            //       md={{
            //         offset: 1,
            //         size: 10
            //       }}
            //       sm="12"
            //     >
                  <Loader isLoading={true}/>
          //        </Col>
          //     </Row>
          //  </div>
            : user &&  !(user.nfts.length>0) ?
            <div>
             <Row>
                <Col
                  className={styles.seemsText} 
                  md={{
                    offset: 1,
                    size: 10
                  }}
                  sm="12"
                >
                  It seems that you haven't claimed any NFTs yet! Start playing to claim some! 
                </Col>
              </Row>
              <AlreadyClaimedNFTs nfts={user.nfts}/>
            </div>
            :
            <div>
              <Row>
                <Col
                  className={styles.recentText} 
                  md={{
                    offset: 1,
                    size: 10
                  }}
                  sm="12"
                >
                  Recently Claimed NFTs
                </Col>
              </Row>
              <Row sm="1" md="2">
                <Col sm="11" md="5">
                 <img src={user.nfts[0].imageUri} className={ styles.nft_img}/>
                </Col>
                <Col  sm="11" md="7">
                  <NFTImagesideCard nft={user.nfts[0]}/>
                </Col>
              </Row>
              <Row sm="1" md="2" className={styles.bottomPart}>
                <Col sm="11" md="5">
                  <NFTDetailsCard nft={user.nfts[0]}/>
                </Col>
                <Col sm="11" md="7">
                  <AlreadyClaimedNFTs nfts={user.nfts}/>
                </Col>
              </Row>
            </div>

          ]
          
        } 
        
      </Container>
    </>
  )
}

export default HomePage
