import JSZip from "jszip";
import QRCode from "qrcode";

export default class DownloadQRCodeZip {

    private zip: JSZip;
    constructor() {
        this.zip = new JSZip();
    }

  public async generateAndDownloadQRCodes(urls: any[] | undefined, nftType: string) {
    await Promise.all(
        urls!.map(async (url, index) => {
          try {
            const canvas = await QRCode.toCanvas(url.url, { width: 300 });
            const blob = await new Promise<Blob>((resolve) => {
              canvas.toBlob((blob: any) => {
                if (!blob) {
                  throw new Error("Failed to create blob.");
                }
                resolve(blob);
              });
            });
        
            this.zip.file(`nftQRCode_${nftType}_${index}.png`, blob);
          } catch (err) {
            console.error(err);
          }
        })
      );

      if(urls!.length !== undefined) {
          this.generateZip(nftType);
      }
  }

  private generateZip(nftType: string) {
    this.zip.generateAsync({ type: "blob" }).then((blob) => {
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = `nftQRCodes_${nftType}.zip`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
  }

  
};
