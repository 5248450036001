import NFT from "../../domain/modals/NFT"
import NFTRepository from "../../domain/repositories/NFTRepository"
import { Query } from "../../domain/repositories/nftRepository/Query";
import NFTDatasource from "../datasources/abstract/NFTDatasource"
import NFTDatasourceImpl from "../datasources/remote/NFTDatasourceImpl"
import { NFTJson } from "../jsons/NFTJson";
import { Query as NFTDatasourceQuery } from "../datasources/abstract/nftDatasource/query"

export default class NFTRepositoryImpl implements NFTRepository {

    private nftDatasource : NFTDatasource;

    constructor () {
        this.nftDatasource = new NFTDatasourceImpl();
    }
    
    findById(id: string): Promise<NFT> {
        return new Promise((resolve, reject) => {
            this.nftDatasource.findById(id).then((json: NFTJson) => {
                resolve(NFT.fromJson(json))
            }).catch(e => {
                reject(e);
            })
        })
    }

    findByIdAndSecurityKey(id:string,securityKey: string): Promise<NFT> {
        return new Promise((resolve, reject) => {
            this.nftDatasource.findByIdAndSecurityKey(id,securityKey).then((json: NFTJson) => {
                resolve(NFT.fromJson(json))
            }).catch(e => {
                reject(e);
            })
        })
    }

    findByOwner(address: string): Promise<NFT[]> {
        return new Promise((resolve, reject) => {
            this.nftDatasource.findByOwner(address).then((jsons: NFTJson[]) => {
                resolve(jsons.map((json) => {return NFT.fromJson(json)}))
            }).catch(e => {
                reject(e);
            })
        })
    }

    findByMinter(address: string): Promise<NFT[]> {
        return new Promise((resolve, reject) => {
            this.nftDatasource.findByMinter(address).then((jsons: NFTJson[]) => {
                resolve(jsons.map((json) => {return NFT.fromJson(json)}))
            }).catch(e => {
                reject(e);
            })
        })
    }

    update(nft: NFT): Promise<NFT> {
        return new Promise((resolve, reject) => {
            this.nftDatasource.update(nft.toUpdateJson()).then((json: NFTJson) => {
                resolve(NFT.fromJson(json))
            }).catch(e => {
                reject(e);
            })
        })
    }

    existsById(id: string): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.nftDatasource.existsById(id).then((result: boolean) => {
                resolve(result)
            }).catch(e => {
                reject(e);
            })
        })
    }

    isClaimed(id: string): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.nftDatasource.isClaimed(id).then((result: boolean) => {
                resolve(result)
            }).catch(e => {
                reject(e);
            })
        })
    }

    search(query: Query): Promise<NFT[]> {
        return new Promise((resolve, reject) => {
            this.nftDatasource.search(NFTDatasourceQuery.fromJson(query.toJson)).then((jsons: NFTJson[]) => {
                resolve(jsons.map((json) => {return NFT.fromJson(json)}))
            }).catch( e => {
                reject(e);
            })
        })
    }

    findAll() : Promise<NFT[]> {
        return new Promise((resolve, reject) => {
            this.nftDatasource.findAll().then((jsons: NFTJson[]) => {
                resolve(jsons.map((json) => {return NFT.fromJson(json)}))
            }).catch( e => {
                reject(e);
            })
        })
    }

    totalCount(query: Query): Promise<number> {
        return new Promise((resolve, reject) => {
            this.nftDatasource.totalCount(NFTDatasourceQuery.fromJson(query.toJson)).then(totalCount => {
                resolve(totalCount)
            }).catch( e => {
                reject(e);
            })
        })
    }

    create(nft: NFT, amount: number, chain: string, image:File): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.nftDatasource.create(nft.toCreateJson(amount, chain, image)).then((result: boolean) => {
                resolve(result)
            }).catch(e => {
                reject(e);
            })
        })
    }

    claim(id: string, securityKey: string, chain: string, publicAddess: string, twitterId?:string): Promise<boolean> {
        return new Promise ((resolve, reject) => {
            this.nftDatasource.claim(id, securityKey,chain, publicAddess,twitterId).then((result: boolean) => {
                resolve(result)
            }).catch(e => {
                reject(e);
            })
        })
    }

    mintAdditionalSupply(chain: string, targetNFTType: string, amount: number): Promise<boolean> {
        return new Promise ((resolve, reject) => {
            this.nftDatasource.mintAdditionalSupply(chain, targetNFTType, amount).then((result: boolean) => {
                resolve(result)
            }).catch(e => {
                reject(e);
            })
        })
    }

    burn(chain: string, targetNFTType: string, amount: number): Promise<boolean> {
        return new Promise ((resolve, reject) => {
            this.nftDatasource.burn(chain, targetNFTType, amount).then((result: boolean) => {
                resolve(result)
            }).catch(e => {
                reject(e);
            })
        })
    }

    getAndDecryptUrls(chain: string, targetNFTType: string, amount: number): Promise<any> {
        return new Promise ((resolve, reject) => {
            this.nftDatasource.getAndDecryptUrls(chain, targetNFTType, amount).then((result: any) => {
                resolve(result)
            }).catch(e => {
                reject(e);
            })
        })
    }
}