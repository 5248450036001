import React from 'react'
import { useState, useEffect } from 'react'
import WalletIcon from '@mui/icons-material/Wallet';
import Button from './Button';
import { useDispatch, useSelector } from "react-redux";
import {useMetamask} from "src/Service/web3"
import {UserController} from 'src/controllers/UserControllers';
import { setAddress, setBalance, setChainId, setIsConnected } from 'src/redux/Actions/Wallet';
import { LOGIN_FAIL, LOGIN_REQUEST, LOGIN_SUCCESS, LOGOUT_SUCCESS } from 'src/redux/Constants/User';

function WalletButton({isHeader}) {
    const {connectWallet, disconnect, checkIfConnected,checkAddress,
      checkBalance,accountChanged, initializeProvider,checkMetamaskAvailability} = useMetamask()
    const {UserLoginStatus,IsUserCurrentPublicAddressLoggedIn,} = UserController()
    const dispatch = useDispatch();

    const {wallet} = useSelector(state => state.wallet)
    const {user, isAuthenticated} = useSelector(state => state.user)
    const [newAccount, setNewAccount] = useState(null)
    const isConnected = async () => {
      const connectionState = await checkIfConnected();
     
      return connectionState;
    }

    const handleConnectWallet = async () => {
        //case loginstatus true
      if(!isAuthenticated){ 
        let result = await UserLoginStatus()
        if(result && result.isLoggedIn===true){
          const accountAdd = await connectWallet()
          if (!accountAdd) {
            return
          }
          let isCurrentAddressLoggedIn = await IsUserCurrentPublicAddressLoggedIn(accountAdd);
          if(isCurrentAddressLoggedIn){
            await checkAddress(accountAdd);
            await checkBalance(accountAdd);
            //dispatch({type:LOGIN_SUCCESS});
            return;
          }else{
            const isDisConnected = await disconnect();
            if(isDisConnected){
              await accountChanged(accountAdd)
            }
            return;
            
          }
          
        }else{
          const accountAdd = await connectWallet()
          if (!accountAdd) {
            return
          }
          await accountChanged(accountAdd)

          return;
        }
      }
    }

    const ethereum = window.ethereum

   
    // Listen for account[] changes
    
    const handleAccountChanged = async(newAccounts)=>{
     
      let result = await UserLoginStatus()
     
      if(result && result.isLoggedIn ){
        if( newAccounts.length === 0) {
          dispatch(setIsConnected(false));
          dispatch({type: LOGOUT_SUCCESS})
          dispatch(setAddress(null))
          dispatch(setBalance(null));
         
        }else {
          setNewAccount(newAccounts[0])
        }
      }
    }

    

     useEffect(() => {
      if(newAccount){
        IsUserCurrentPublicAddressLoggedIn(newAccount).then(async(isCurrentAddressLoggedIn)=>{
          if(isCurrentAddressLoggedIn){
            // dispatch({type:LOGIN_REQUEST});
            checkMetamaskAvailability()
            dispatch(setIsConnected(true));
            await checkAddress(newAccount);
            await checkBalance(newAccount);
            // dispatch({type:LOGIN_SUCCESS});
          
            return;
          }else{
            disconnect().then(async(isDisConnected)=>{
              if(isDisConnected){
                await accountChanged(newAccount)
              }
            })
          }

        })
       
      }
      
    }, [newAccount])


    ethereum.on('accountsChanged', handleAccountChanged);

    // Listen for network changes
    ethereum.on('chainChanged', () => {
      window.location.reload();
      // if(isAuthenticated){
      //   dispatch(setChainId(newNetworkId))
      // }
     
    });

  useEffect(() => {
    if(wallet.isMetamaskInstalled) {
      initializeProvider()
      isConnected();
    
      
    }
  }, [wallet.isMetamaskInstalled])

      return (
        <>
        { isHeader ?
           <>
                <Button className='d-none d-sm-block' variant='outlined' color="success" onClick={handleConnectWallet}>{
                  wallet.address ? ("Connected : " + 
                    String(wallet.address).substring(0,4)
                        + "..." + 
                        String(wallet.address).substring(wallet.address.length - 4,wallet.address.length - 1))
                        : <span>Connect Wallet</span>
                    
                    }
                </Button>
                <Button className='d-sm-none' variant='outlined' color="success" onClick={handleConnectWallet}>{
                   wallet.address ? 
                    String(wallet.address).substring(0,4)
                    + "..." + 
                    String(wallet.address).substring(wallet.address.length - 4, wallet.address.length - 1)
                        : <WalletIcon/>
                    
                    }
                </Button>
           </>


        :
        <Button variant='outlined' color="success" onClick={handleConnectWallet}>{
            wallet.address ? ("Connected : " + 
            String(wallet.address).substring(0,4)
                + "..." + 
                String(wallet.address).substring(wallet.address.length - 4, wallet.address.length - 1))
                : <span>Connect Wallet</span>
            
            }
        </Button>

        }
            
        </>
    )
}

export default WalletButton;
/*
let result = await UserLoginStatus()
      if(result && result.isLoggedIn ){
        console.log(result.isLoggedIn)
        console.log(newAccounts.length)
        if(stop===0 && newAccounts.length === 0) {
          dispatch(setIsConnected(false));
          dispatch({type: LOGOUT_SUCCESS})
          dispatch(setAddress(null))
          dispatch(setBalance(null));
          stop=1;
        }else if(stop===0){
          let isCurrentAddressLoggedIn = await IsUserCurrentPublicAddressLoggedIn(newAccounts[0]);
          if(isCurrentAddressLoggedIn){
            // dispatch({type:LOGIN_REQUEST});
            checkMetamaskAvailability()
            dispatch(setIsConnected(true));
            await checkAddress(newAccounts[0]);
            await checkBalance(newAccounts[0]);
            // dispatch({type:LOGIN_SUCCESS});
            stop=1;
            return;
          }else{
            const isDisConnected=  await disconnect()
            if(isDisConnected){
              await accountChanged(newAccounts[0])
            }
            stop=1;
          }
          
            // const isDisConnected = await disconnect();
            // if(isDisConnected){
            //   await accountChanged(newAccounts[0])
            // }
            // return;
            
        }
      }
*/