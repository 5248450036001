import React from "react";
import styles from "./sidebar.module.css";
import { Link } from "react-router-dom";
import AddIcon from '@mui/icons-material/Add';
import InventoryIcon from '@mui/icons-material/Inventory';
import PeopleIcon from '@mui/icons-material/People';
import FolderZipIcon from '@mui/icons-material/FolderZip';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import RemoveIcon from '@mui/icons-material/Remove';
const Sidebar = () => {
  return (
    <div className={styles.sidebar}>
      {/* <Link to="/admin">
        <p>
          <DashboardIcon /> Admins
        </p>
      </Link> */}
      <Link to="/admin/users">
        <p>
          <PeopleIcon /> Users
        </p>
      </Link>
      <Link to="/admin/nfts">
        <p>
          <InventoryIcon/> NFTS
        </p>
      </Link>
      <Link to="/admin/nft/mint/new">
        <p>
          <AddIcon/> MINT new NFTs(new type)
        </p>
      </Link>
      <Link to="/admin/nft/mint/additionSupply">
        <p>
          <AddIcon/> MINT more NFTs(existing type)
        </p>
      </Link>
      {/* <Link to="/admin/nft/mint/burn">
        <p>
          <RemoveIcon/> Burn NFTs
        </p>
      </Link> */}
      <Link to="/admin/nft/download/qrs">
        <p>
          <FolderZipIcon /> Download NFT QrCode
        </p>
      </Link>
      <Link to="/admin/nft/download/links">
        <p>
          <FileDownloadIcon /> Download NFT Links
        </p>
      </Link>
    </div>
  );
};

export default Sidebar;