import NFTRepositoryImpl from "src/data/repositories/NFTRepositoryImpl";
import LoginStatus from "src/domain/modals/LoginStatus";
import LoginSuccess from "src/domain/modals/LoginSuccess";
import NFT from "src/domain/modals/NFT";
import NFTRepository from "src/domain/repositories/NFTRepository";
import { Query } from "src/domain/repositories/nftRepository/Query";
import BaseError from "src/errors/BaseError";
import { ADD_SUPPLY_NFT_FAIL, ADD_SUPPLY_NFT_REQUEST, ADD_SUPPLY_NFT_SUCCESS, CLAIM_NFT_FAIL, CLAIM_NFT_REQUEST, CLAIM_NFT_SUCCESS, CREATE_NFT_FAIL, CREATE_NFT_REQUEST, CREATE_NFT_SUCCESS, FETCH_NFTS_FAIL, FETCH_NFTS_REQUEST, FETCH_NFTS_SUCCESS, NFT_LINK_FAIL, NFT_LINK_REQUEST, NFT_LINK_SUCCESS } from "src/redux/Constants/NFT";
import { useDispatch, useSelector } from "react-redux";
import decryptData from "src/Utils/DecryptUrls";
import DownloadUrls from "src/Utils/DownloadUrls";
import { toast } from 'react-toastify'
import DownloadQRCodeZip from "src/Utils/DownloadQRs";

export function NFTController() {
    const downloadQRCodeZip = new DownloadQRCodeZip();
    const nftRepository : NFTRepository = new NFTRepositoryImpl();
    const dispatch = useDispatch();

    const findById = async (id: string) => {
        try {
            const nft: NFT = await nftRepository.findById(id);
            return nft;
        } catch (e) {
            let err;
            if(e instanceof BaseError){
                err = e.getErrors()
                err = err[0]
            }else{
                err = e
            }
            toast.error(err as any)
        }
    }

    const findByIdAndSecurityKey = async (id: string,securityKey:string) => {
        try {
            const nft: NFT = await nftRepository.findByIdAndSecurityKey(id,securityKey);
            return nft;
        } catch (e) {
            let err;
            if(e instanceof BaseError){
                err = e.getErrors()
                err = err[0]
            }else{
                err = e
            }
            toast.error(err as any)
        }
    }
    
    const findByOwner = async (ownerAddress: string) => {
        try {
            const nft: NFT[] = await nftRepository.findByOwner(ownerAddress);
            return nft;
        } catch (e) {
            let err;
            if(e instanceof BaseError){
                err = e.getErrors()
                err = err[0]
            }else{
                err = e
            }
        }
    }
    
    const findByMinter = async (minter: string) => {
        try {
            const nft: NFT[] = await nftRepository.findByMinter(minter);
            return nft;
        } catch (e) {
            let err;
            if(e instanceof BaseError){
                err = e.getErrors()
                err = err[0]
            }else{
                err = e
            }
        }
    }

    const update = async (nftEntity: NFT) => {
        try {
            const nft: NFT = await nftRepository.update(nftEntity);
            return nft;
        } catch (e) {
            let err;
            if(e instanceof BaseError){
                err = e.getErrors()
                err = err[0]
            }else{
                err = e
            }
            
        }
    }
    
    const existsById = async (id: string) => {
        try {
            const result: boolean = await nftRepository.existsById(id);
            return result;
        } catch (e) {
            let err;
            if(e instanceof BaseError){
                err = e.getErrors()
                err = err[0]
            }else{
                err = e
            }
            toast.error(err as any)
        }
    }

    const isClaimed = async (id: string) => {
        try {
            const result: boolean = await nftRepository.isClaimed(id);
            return result;
        } catch (e) {
            let err;
            if(e instanceof BaseError){
                err = e.getErrors()
                err = err[0]
            }else{
                err = e
            }
            toast.error(err as any)
        }
    }

    const search = async (params: any) => {
        try {
            const query = new Query();
            if(params) {
                if (params['id'] !== undefined) {
                    query.id = params['id']
                }
                if (params['contractAddress'] !== undefined) {
                    query.contractAddress = params['contractAddress']
                }
                if (params['limit'] !== undefined) {
                    query.limit = params['limit']
                }
                if (params['maxCreatedAt'] !== undefined) {
                    query.maxCreatedAt = params['maxCreatedAt']
                }
                if (params['minCreatedAt'] !== undefined) {
                    query.minCreatedAt = params['minCreatedAt']
                }
                if (params['offset'] !== undefined) {
                    query.offset = params['offset']
                }
                if (params['sortBy'] !== undefined) {
                    query.sortBy = params['sortBy']
                }
            }
            const nfts: NFT[] = await nftRepository.search(query);
            return nfts;
        } catch (e) {
            let err;
            if(e instanceof BaseError){
                err = e.getErrors()
                err = err[0]
            }else{
                err = e
            }
            toast.error(err as any)
        }
    }

    const fetchAll = async () => {
        try {
            dispatch({ type: FETCH_NFTS_REQUEST});
            const nfts: NFT[] = await nftRepository.findAll();
            dispatch({ type: FETCH_NFTS_SUCCESS, payload: nfts });
            return nfts;
        } catch (e) {
            let err;
            if(e instanceof BaseError){
                err = e.getErrors()
                err = err[0]
            }else{
                err = e
            }
            dispatch({ type: FETCH_NFTS_FAIL, payload: err });
            
        }
    }
    
    const totalCount = async (params: any) => {
        try {
            const query = new Query();
            if(params) {
                if (params['id'] !== undefined) {
                    query.id = params['id']
                }
                if (params['contractAddress'] !== undefined) {
                    query.contractAddress = params['contractAddress']
                }
                if (params['limit'] !== undefined) {
                    query.limit = params['limit']
                }
                if (params['maxCreatedAt'] !== undefined) {
                    query.maxCreatedAt = params['maxCreatedAt']
                }
                if (params['minCreatedAt'] !== undefined) {
                    query.minCreatedAt = params['minCreatedAt']
                }
                if (params['offset'] !== undefined) {
                    query.offset = params['offset']
                }
                if (params['sortBy'] !== undefined) {
                    query.sortBy = params['sortBy']
                }
            }
            const totalCount: number = await nftRepository.totalCount(query);
            return totalCount;
        } catch (e) {
            let err;
            if(e instanceof BaseError){
                err = e.getErrors()
                err = err[0]
            }else{
                err = e
            }
            
        }
    }

    const create = async (nftEntity: NFT, amount: number, chain: string, image:File) => {
        try {
            dispatch({type:CREATE_NFT_REQUEST})
            const result = await nftRepository.create(nftEntity, amount, chain, image);
            dispatch({type:CREATE_NFT_SUCCESS, payload:result})
            return result;
        } catch (e) {
            let err;
            if(e instanceof BaseError){
                err = e.getErrors()
                err = err[0]
            }else{
                err = e
            }
            dispatch({type:CREATE_NFT_FAIL, payload:err})
        }
    }

    const claim = async (id: string, securityKey: string, chain: string, publicAddress: string, twitterId?:string) => {
        try {
            dispatch({type:CLAIM_NFT_REQUEST})
            const result: boolean = await nftRepository.claim(id, securityKey, chain, publicAddress,twitterId);
            dispatch({type:CLAIM_NFT_SUCCESS, payload:result})
            return result;
        } catch (e) {
            let err;
            if(e instanceof BaseError){
                err = e.getErrors()
                err = err[0]
            }else{
                err = e
            }
            dispatch({type:CLAIM_NFT_FAIL, payload:err})
        }
    }
    
    const mintAdditionalSupply = async (chain: string, nftType: string, amount: number) => {
        try {
            dispatch({type:ADD_SUPPLY_NFT_REQUEST})
            const result: boolean = await nftRepository.mintAdditionalSupply(chain, nftType, amount);
            dispatch({type:ADD_SUPPLY_NFT_SUCCESS, payload:result})
            return result;
        } catch (e) {
            let err;
            if(e instanceof BaseError){
                err = e.getErrors()
                err = err[0]
            }else{
                err = e
            }
            dispatch({type:ADD_SUPPLY_NFT_FAIL, payload:err}) 
        }
    }

    const burn = async (chain: string, nftType: string, amount: number) => {
        try {
            const result: boolean = await nftRepository.burn(chain, nftType, amount);
            return result;
        } catch (e) {
            // console.log("Something went wrong!", e);
            let err;
            if(e instanceof BaseError){
                err = e.getErrors()
                err = err[0]
            }else{
                err = e
            }
            
        }
    }

    const getAndDecryptUrls = async (chain: string, nftType: string, amount: number, password: string) => {
        const result: any = await nftRepository.getAndDecryptUrls(chain, nftType, amount);
        return decryptData(result, password)
    }

    const downloadNftLinks = async (chain: string, nftType: string, amount: number, password: string) => {
        try {
            dispatch({type:NFT_LINK_REQUEST})
            const decryptedCsvData = await getAndDecryptUrls(chain, nftType, amount, password);
            DownloadUrls(decryptedCsvData, `nft_urlLinks_${nftType}`);
            dispatch({type:NFT_LINK_SUCCESS})
            return true;
        } catch (e) {
            let err;
            let errorOrigin = 'backend'
            if(e instanceof BaseError){
                err = e.getErrors()
                err = err[0]
            }else{
                err = e
                errorOrigin = 'frontend'
            }
            dispatch({type:NFT_LINK_FAIL, payload: err})
            return {result: false, errorOrigin: errorOrigin};
        }
    }
    
    const downloadQRCodes = async (chain: string, nftType: string, amount: number, password: string) => {
        try {
            dispatch({type:NFT_LINK_REQUEST})
            const decryptedCsvData = await getAndDecryptUrls(chain, nftType, amount, password);
            await downloadQRCodeZip.generateAndDownloadQRCodes(decryptedCsvData, nftType)
            dispatch({type:NFT_LINK_SUCCESS})
            return true;
        } catch (e) {
            let err;
            let errorOrigin = 'backend'
            if(e instanceof BaseError){
                err = e.getErrors()
                err = err[0]
            }else{
                err = e
                errorOrigin = 'frontend'
            }
            dispatch({type:NFT_LINK_FAIL, payload: err})
            return {result: false, errorOrigin: errorOrigin};
        }
    }

    return {
        findById,
        findByIdAndSecurityKey,
        findByOwner,
        findByMinter,
        update,
        existsById,
        isClaimed,
        search,
        fetchAll,
        totalCount,
        create,
        claim,
        mintAdditionalSupply,
        burn,
        downloadNftLinks,
        downloadQRCodes
    }
}