import React, {Fragment,useEffect, useState } from 'react';
import Button  from '../../Elements/Buttons/Button';
import Sidebar from './Sidebar';
import styles from './MintNft.module.css'
import { Col, Form, FormGroup, Input, Label } from 'reactstrap';
import {NFTController} from 'src/controllers/NFTControllers';
import { MdCloudUpload} from 'react-icons/md'
import NFT from 'src/domain/modals/NFT';
import { TargetNFTType } from 'src/domain/value_objects/TargetNftType';
import { useDispatch, useSelector } from "react-redux";
import { clearErrors } from 'src/redux/Actions/User';
import Chain from 'src/domain/value_objects/Chain';
import { toast } from 'react-toastify';
import PleaseWaitModal from 'src/components/Layout/Loader/PleaseWait';
import { CREATE_NFT_RESET } from 'src/redux/Constants/NFT';

function MintNft() {
    const dispatch =  useDispatch()

    const {create} = NFTController()

    const {wallet,error}  = useSelector((state) => state.wallet)
    const {loading,success, error:nftCreateError }  = useSelector((state) => state.nftEditOrCreate)
    
    const [file, setFile] = useState(null);
    const [amount, setAmount] = useState("");
    const [targetNFTType, setTargetNFTType] = useState(TargetNFTType.getResourceArray()[0].label);
    const [description, setDescription] = useState(null);
    const [name, setName] = useState(null);
    const [fileName, setFileName] = useState("No selected file")
    const [previewFile, setPreviewFile] = useState(null)

   
    const resetFormData = ()=>{
        setAmount(0);
        setDescription(null);
        setFile(null)
        setFileName("No selected file");
        setName(null);
        setTargetNFTType(TargetNFTType.getResourceArray()[0].label);
        setPreviewFile(null);
    }

    const handleSubmit = async(event) =>{
        event.preventDefault();
        if(wallet.chainId){
            const chain = Chain.fromId(wallet.chainId);

            if(!file){
                toast.warning("Please upload a file")
                return;
            }
            if(!name){
                toast.warning("Please enter a name")
                return;
            }
            if(!amount){
                toast.warning("Please enter an amount")
                return;
            }
            if(!chain || chain.isUnknown()){
                toast.warning("Invalid Network")
                return;
            }

            const newFile= new File([file], file.name, {type:file.type})
            if( !targetNFTType){
                toast.warning("Please select the NFT type")
                return;
            }
            const nftTypeInstance = TargetNFTType.fromLabel(targetNFTType)
    
            const nftType = {
                id : nftTypeInstance.getId(),
                name: nftTypeInstance.getName(),
                label: nftTypeInstance.getLabel(),
            }
            const newNFT = new NFT(null,"useless",null,null,name,description,"false",nftType,"kuchBhi",null, null,null)
           
            
            const result = await create(newNFT,amount,chain.getName(),newFile);
            if(result){
                resetFormData()
            }
            return;
        
        }else{
            toast.error("Chain id couldn't detected, please refresh to avoid error")
        }
    
    }
    const handleImage = (event)=>{
       
        setPreviewFile(URL.createObjectURL(event.target.files[0]))
        setFile(event.target.files[0])
        setFileName(event.target.files[0].name)
       
    }
   
    useEffect(() => {
        if (error) {
            toast.error(error);
            dispatch(clearErrors());
        }
        if (nftCreateError) {
            toast.error(nftCreateError);
            dispatch(clearErrors());
        }
        if (success) {
            toast.success("New NFT Minted Successfully");
            dispatch(clearErrors());
            dispatch({ type: CREATE_NFT_RESET });
        }
    }, [dispatch, error,nftCreateError, success])

  return (
    <Fragment>
        <div className={styles.dashboard}>
            <Sidebar  />
            <div className={styles.adminListContainer}>
              <h1 id={styles.adminListHeading}>Mint NFT</h1>
              <Form className={styles.form} >
                    <FormGroup col>
                        <Label for="name" sm={2}>
                           Name
                        </Label>
                        <Col sm={10}>
                            <Input
                                required={true}
                                id="name"
                                name="dname"
                                type="text"
                                className={styles.text}
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup col>
                        <Label for="media" sm={2}>
                            Media
                        </Label>    
                        <div className={previewFile ? styles.imageBoxWithImage :styles.imageBox} 
                            onClick={() => document.querySelector(".input-field").click()}
                            >
                                <input type="file" className='input-field' hidden required={true}
                                     onChange={handleImage}
                                />

                            {previewFile ?
                            <img src={previewFile} className={styles.image} alt={fileName} />
                            : 
                            <>
                            <MdCloudUpload color='#1475cf' size={60} />
                            <p>Browse Files to upload</p>
                            </>
                            }
                        </div> 
                    </FormGroup>
    
                    <FormGroup col>
                        <Label for="type" sm={2}>
                         NFT Type 
                        </Label>
                        <Col sm={10}>
                            <Input
                                required={true}
                                name="select"
                                type="select"
                                className={styles.text}
                                value={targetNFTType}
                                onChange={(e) => setTargetNFTType(e.target.value)}
                            >
                                {
                                    TargetNFTType.getResourceArray().map((item)=>(
                                        <option>
                                          {item.label}
                                       </option>
                                    ))
                                }
                                
                            </Input>
                        </Col>
                    </FormGroup>
                    <FormGroup col>
                        <Label for="description" sm={2}>
                          Description
                        </Label>
                        <Col sm={10}>
                            <Input
                                id="description"
                                name="description"
                                type="textarea"
                                className={styles.text}
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                            />
                        </Col>
                    </FormGroup>
                    
                    <FormGroup col>
                        <Label for="supply" sm={2}>
                        Initial Supply
                        </Label>
                        <Col sm={10}>
                            <Input
                                id="supply"
                                name="supply"
                                type="text"
                                //onWheel={handleWheel}
                                className={styles.number}
                                required={true}
                                value={amount}
                                //onKeyDown={handleListKeyDown}
                                onChange={(e) => {
                                    const regex = /^[0-9\b]+$/; // regular expression to allow only digits
                                    const value = e.target.value
                                    if (value === '' || regex.test(value)) {
                                        setAmount(value)
                                    }
                                    // if(e.target.value<0){
                                    //     setAmount(0)
                                    // }else{
                                    //     setAmount(e.target.value)
                                    // }
                                    
                                }}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup check row>
                        <Col sm={{offset: 8, size: 2.5}}>
                            <Button variant='contained' onClick={handleSubmit}> Mint NFT </Button>
                        </Col>
                    </FormGroup>
                </Form>
              
            </div>
        </div>
        <PleaseWaitModal showModal={loading? true : false}/>
    </Fragment>
  );
}
export default MintNft;

