export const SET_WALLET_ADDRESS_SUCCESS = "SET_WALLET_ADDRESS_SUCCESS"
export const SET_WALLET_ADDRESS_FAIL = "SET_WALLET_ADDRESS_FAIL"

export const SET_WALLET_CHAINID_SUCCESS = "SET_WALLET_CHAINID_SUCCESS"
export const SET_WALLET_CHAINID_FAIL = "SET_WALLET_CHAINID_FAIL"

export const SET_WALLET_BALANCE_SUCCESS = "SET_WALLET_BALANCE_SUCCESS"
export const SET_WALLET_BALANCE_FAIL = "SET_WALLET_BALANCE_FAIL"

export const SET_WALLET_INSTALLED_SUCCESS = "SET_WALLET_INSTALLED_SUCCESS"
export const SET_WALLET_INSTALLED_FAIL = "SET_WALLET_INSTALLED_FAIL"

export const SET_WALLET_CONNECTED_SUCCESS = "SET_WALLET_CONNECTED_SUCCESS"
export const SET_WALLET_CONNECTED_FAIL = "SET_WALLET_CONNECTED_FAIL"

export const CLEAR_ERRORS = "CLEAR_ERRORS";