import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

function PleaseWaitModal({showModal}) {
  return (
    <div>
      <Dialog open={showModal} >
        <DialogTitle>Transaction under Process</DialogTitle>
        <DialogContent style={{ display:'flex', alignItems:'center', flexDirection:'column'}}>
          <DialogContentText>
            Please wait...
          </DialogContentText>
          <CircularProgress style={{marginTop: '1rem'}} />
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default PleaseWaitModal;
