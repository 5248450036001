import React, {Fragment,useEffect} from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Link } from "react-router-dom";
import Box from '@mui/material/Box';
import styles from './Admins.module.css'
import Sidebar from './Sidebar';
import Button from '../../Elements/Buttons/Button'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {NFTController} from 'src/controllers/NFTControllers';
import { useDispatch, useSelector } from "react-redux";
import Loader from 'src/components/Layout/Loader/Loader';
import { toast } from 'react-toastify'
import { clearErrors } from 'src/redux/Actions/User';

function NFTs() {
    const dispatch =  useDispatch();

    const {fetchAll} = NFTController()
    const {error,loading,nfts}  = useSelector((state) => state.nfts);

    useEffect(() => {
        fetchAll()
      }, []);
    
      useEffect(() => {
        if (error) {
          toast.error(error)
          dispatch(clearErrors());
        }
        
      }, [dispatch, error]);
      
    const columns = [ 
        { 
            field: "id", 
            headerName:"ID",
            headerClassName: 'super-app-theme--header',
            headerAlign: 'center',
            minWidth: 180, 
            flex: 0.8 
        },
        { 
            field: "tokenName", 
            headerName:"Name",
            headerClassName: 'super-app-theme--header',
            headerAlign: 'center',
            align:"center",
            minWidth: 140, 
            flex: 0.8 
        },
        {
            field: "nftType",
            headerName: "Type",
            headerClassName: 'super-app-theme--header',
            headerAlign: 'center',
            align:"center",
            minWidth: 140,
            flex: 0.4,
        },
        {
            field: "isClaimed",
            headerName: "Claim Status",
            headerClassName: 'super-app-theme--header',
            type: "number",
            headerAlign: 'center',
            align:"center",
            minWidth: 110,
            flex: 0.3,
            cellClassName: (params) => {
                return params.row.isClaimed === "true"
                ? "greenColor"
                : "redColor";
            },
        },
        { 
            field: "minter", 
            headerName:"Minter",
            headerClassName: 'super-app-theme--header',
            headerAlign: 'center',
            minWidth: 180, 
            flex: 0.6 
        },
        { 
            field: "owner", 
            headerName:"Owner",
            headerClassName: 'super-app-theme--header',
            headerAlign: 'center',
            minWidth: 180, 
            flex: 0.6 
        },
        { 
            field: "address", 
            headerName:"Contract Address",
            headerClassName: 'super-app-theme--header',
            headerAlign: 'center',
            minWidth: 180, 
            flex: 0.6 
        },
        { 
            field: "createdAt", 
            headerName:"createdAt",
            headerClassName: 'super-app-theme--header',
            headerAlign: 'center',
            minWidth: 180, 
            flex: 0.6 
        },
        { 
            field: "updatedAt", 
            headerName:"UpdatedAt",
            headerClassName: 'super-app-theme--header',
            headerAlign: 'center',
            minWidth: 180, 
            flex: 0.6 
        },
        // {
        //   field: "actions",
        //   flex: 0.3,
        //   headerName: "Actions",
        //   headerClassName: 'super-app-theme--header',
        //   minWidth: 150,
        //   type: "number",
        //   sortable: false,
        //   cellClassName: (params) => {
        //     return "redColors"
        //   },
        
        //   renderCell: (params) => {
        //     return (
        //       <Fragment>
        //         <Link className={styles.actionCell} to={`/admin/nft/edit/${params.row.id}`}>
        //           <EditIcon />
        //         </Link>
        //       </Fragment>
        //     );
        //   },
        // },
    
]

  const rows = [];
  

  nfts &&  nfts.forEach((item) => {
      rows.push( 
        {
          id: item.id,
          tokenName: item.tokenName,
          nftType: item.nftType ? item.nftType.label : null,
          isClaimed: item.isClaimed ,
          minter:item.minter,
          owner: item.owner,
          address: item.contractAddress,
          createdAt: item.createdAt,
          updatedAt:item.updatedAt
        });
    });
      return (
        <Fragment>
            <div className={styles.dashboard}>
            <Sidebar />
            <div className={styles.adminListContainer}>
              <h1 id={styles.adminListHeading}>NFTS</h1>
              {
                loading ?
                 <Loader isLoading={loading ? true: false}/>
                :
                <Box 
                    sx={{
                        '& .super-app-theme--header': {
                            backgroundColor: "#1c1c1c20"
                        },
                        '& .redColors':{
                        color: "tomato"
                        },
                        '& .greenColor':{
                            color: "green"
                        },
                        '& .redColor':{
                            color: "tomato"
                        },
                        maxWidth: "95%",
                        alignSelf:"center",
                        boxShadow: "1px 1px 3px grey"
                    }}
                >
                    <DataGrid
                        autoHeight
                        rows={rows}
                        columns={columns}
                        pageSize={3}
                        disableSelectionOnClick
                        
                    />
                </Box>
            }
             </div>
           </div>
         </Fragment>
       
      );
};


export default NFTs;