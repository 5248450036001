import {
    LOAD_NFT_DATA_FAIL,
    FETCH_NFTS_FAIL,
    FETCH_NFTS_SUCCESS,
    FETCH_NFTS_REQUEST,
    CREATE_NFT_REQUEST,
    CREATE_NFT_FAIL,
    CREATE_NFT_SUCCESS,
    CREATE_NFT_RESET,
    ADD_SUPPLY_NFT_REQUEST,
    ADD_SUPPLY_NFT_FAIL,
    ADD_SUPPLY_NFT_SUCCESS,
    ADD_SUPPLY_NFT_RESET,
    CLAIM_NFT_REQUEST,
    CLAIM_NFT_FAIL,
    CLAIM_NFT_SUCCESS,
    CLAIM_NFT_RESET,
    FETCH_NFTS_COUNT_SUCCESS,
    FETCH_NFTS_COUNT_FAIL,
    CLEAR_ERRORS,
    NFT_LINK_FAIL,
    NFT_LINK_REQUEST,
    NFT_LINK_SUCCESS
  } from "../../Constants/NFT/index";
  
  export const fetchAllNFTReducer = (state = { nfts: [] }, action: { type: any; payload: any; }):any => {
    switch (action.type) {
      case FETCH_NFTS_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case FETCH_NFTS_SUCCESS:
        return {
          ...state,
          loading: false,
          nfts: action.payload,
        };
      //case LOAD_NFT_DATA_FAIL:
      case FETCH_NFTS_FAIL :
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
    // case FETCH_NFTS_COUNT_FAIL :
    //     return {
    //         loading : false,
    //         count: null,
    //         error: action.payload,
    //     }
    case CLEAR_ERRORS:
        return {
        ...state,
        error: null,
        };
      default:
        return state;
    }
  };

  export const nftReducer = (state = {}, action: { type: any; payload: any; }):any => {
    switch (action.type) {
      case CREATE_NFT_REQUEST:
      case ADD_SUPPLY_NFT_REQUEST:
      case CLAIM_NFT_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case CREATE_NFT_SUCCESS:
      case ADD_SUPPLY_NFT_SUCCESS:
      case CLAIM_NFT_SUCCESS:
        return {
          loading: false,
          success: action.payload
        };
      case CREATE_NFT_FAIL :
      case ADD_SUPPLY_NFT_FAIL :
      case CLAIM_NFT_FAIL :
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      case CREATE_NFT_RESET:
      case ADD_SUPPLY_NFT_RESET:
      case CLAIM_NFT_RESET:
        return {
          ...state,
          success: false,
        };
   
      case CLEAR_ERRORS:
          return {
          ...state,
          error: null,
          };
        default:
          return state;
    }
  };
  export const nftLinkReducer = (state = {}, action: { type: any; payload: any; }):any => {
    switch (action.type) {
      case NFT_LINK_REQUEST: 
      return {
        loading: true
      }
      case NFT_LINK_SUCCESS: 
      return {
        loading: false,
      }
      case NFT_LINK_FAIL: 
      return {
        loading: false,
        error: action.payload
      }
      case CLEAR_ERRORS:
          return {
          ...state,
          error: null,
          };
      default:
        return state;
    }
  };

 