import { BaseModel } from './BaseModel'
import User from './User'
import { LoginStatusJson } from '../../data/jsons/LoginStatusJson'

export default class LoginStatus implements BaseModel {

  public static fromJson(json:LoginStatusJson): LoginStatus {

    return new this(
      json.isLoggedIn,
      (json.user !== null) ? User.fromJson(json.user) : null
    )
  }

  constructor(
    public isLoggedIn: boolean,
    public user: User|null
  ){}

  public toJson(): LoginStatusJson {
    return {
      isLoggedIn: this.isLoggedIn,
      user: (this.user !== null) ? this.user.toJson() : null
    }
  }
}
