export default abstract class BaseError extends Error {

    protected errors:string[] = []

    constructor(message:string, errors:string[]) {
        super(message)
        this.errors = errors
    }

    public getMessage(): string {
        return this.message
    }

    public getErrors(): string[] {
        return this.errors
    }
}