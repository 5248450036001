import {
  SET_WALLET_ADDRESS_SUCCESS,
  SET_WALLET_ADDRESS_FAIL,
  SET_WALLET_CHAINID_SUCCESS,
  SET_WALLET_CHAINID_FAIL,
  SET_WALLET_BALANCE_SUCCESS,
  SET_WALLET_BALANCE_FAIL,
  SET_WALLET_INSTALLED_SUCCESS,
  SET_WALLET_INSTALLED_FAIL,
  SET_WALLET_CONNECTED_SUCCESS,
  SET_WALLET_CONNECTED_FAIL
  } from "../../Constants/Wallet/index";
  
  
    export const setAddress = (publicAddress:string|null) => async (dispatch: (arg0: { type: string; payload?: any; }) => void) => {
      try {
    
        dispatch({ type: SET_WALLET_ADDRESS_SUCCESS, payload: publicAddress });
      } catch (error) {
        dispatch({ type: SET_WALLET_ADDRESS_FAIL, payload: error });
      }
    };
    
    export const setChainId = (id:number) => async (dispatch: (arg0: { type: string; payload?: any; }) => void) => {
      try {
    
        dispatch({ type: SET_WALLET_CHAINID_SUCCESS, payload: id });
      } catch (error) {
        dispatch({ type: SET_WALLET_CHAINID_FAIL, payload: error });
      }
       
    };

    export const setBalance = (balance:string|null) => async (dispatch: (arg0: { type: string; payload?: any; }) => void) => {
      try {
    
        dispatch({ type: SET_WALLET_BALANCE_SUCCESS, payload:balance });
      } catch (error) {
        dispatch({ type: SET_WALLET_BALANCE_FAIL, payload: error });
      }
       
    };

    export const setIsMetamaskInstalled = (status:boolean) => async (dispatch: (arg0: { type: string; payload?: any; }) => void) => {
      try {
    
        dispatch({ type: SET_WALLET_INSTALLED_SUCCESS, payload:status });
      } catch (error) {
        dispatch({ type: SET_WALLET_INSTALLED_FAIL, payload: error });
      }
       
    };

    export const setIsConnected = (status:boolean) => async (dispatch: (arg0: { type: string; payload?: any; }) => void) => {
      try {
    
        dispatch({ type: SET_WALLET_CONNECTED_SUCCESS, payload:status });
      } catch (error) {
        dispatch({ type: SET_WALLET_CONNECTED_FAIL, payload: error });
      }
       
    };

