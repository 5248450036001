import React from 'react';
import Button from '@mui/material/Button';

export default function CustomButton({ children, className, onClick, ...props }) {

  return (
    <Button
      className = {`${className}`}
      variant= {props.variant ? props.variant : 'outlined' }
      color= {props.color ? props.color : "success" }
      onClick={onClick}
      {...props}
    >
      {children}
    </Button>
  );
}