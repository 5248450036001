import Base from "./Base";

export default class Chain extends Base {
    public static getResourceArray() {
        return [
            {
                id: 80001,
                name: "polygon mumbai",
                label: "Polygon Mumbai",
            },
            {
                id: 137,
                name: "polygon",
                label: "Polygon",
            },
            
        ];
    }

    public static polygonMumbai(): Chain {
        return this.fromName("polygon mumbai");
    }

    public static polygon(): Chain {
        return this.fromName("polygon");
    }
    
    public isPolygonMumbai(): boolean {
        return this.getName() === "polygon mumbai";
    }

    public isPolygon(): boolean {
        return this.getName() === "polygon";
    }
    
}
