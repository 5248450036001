import React from "react";
import { BottomNavigation, BottomNavigationAction, Typography, Link } from "@mui/material";
import { LinkedIn, Twitter, Instagram } from "@mui/icons-material";

function Footer() {
  const footerStyle = {
    width: "-webkit-fill-available",
    position: "fixed",
    zIndex: 100,
    bottom: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "auto",
    padding: "10px 20px",
    justifyContent: "space-between",
    background: "lightGrey",
    flexDirection:"column"
  };

  const displayStyle1 = {
    display: "flex",
    height: "10vh",
    justifyContent: "space-around",
    flexDirection : "column"
  }
  const displayStyle2 = {
    display: "flex",
    flexDirection : "row"
  }
  const umbrellaStyle = {
    display:"flex",
    flexDirection: "row",
    width: "-webkit-fill-available",
    justifyContent: "space-between"
  }
  // const logoStyles = {
  //   padding: "0"
  // }

  return (

    <BottomNavigation style={footerStyle}>
      <div style={umbrellaStyle}>
      <div style={displayStyle1}>

      <Link href="https://cryptoloungegox.com/#:~:text=event%20list-,ABOUT,-About%20Crypto%20Lounge" color="inherit" target="_blank">
        About Us
      </Link>
      <Link href="https://t2web.co.jp/contact/" color="inherit" target="_blank">
        Contact Us
      </Link>
      </div>
      <div style={displayStyle2}>

      <BottomNavigationAction
        // style={logoStyles}
        label="Instagram"
        icon={<Instagram />}
        href="https://www.instagram.com/cryptoloungegox/"
        target="_blank"
        rel="noopener noreferrer"
        />
      {/* <BottomNavigationAction
        // style={logoStyles}
        label="LinkedIn"
        icon={<LinkedIn />}
        href="https://www.linkedin.com/company/t2web-corporation/"
        target="_blank"
        rel="noopener noreferrer"
        /> */}
      <BottomNavigationAction
        // style={logoStyles}
        label="Twitter"
        icon={<Twitter />}
        href="https://twitter.com/groove_on_x"
        target="_blank"
        rel="noopener noreferrer"
      />
      </div>
      </div>
      <Typography variant="body2" color="inherit" >
      Copyright ©2023 T2WEB Corporation
      </Typography>
    </BottomNavigation>
      
  );
}

export default Footer;
